import { createContext, useContext, DependencyList } from 'react';
import TemplateObject from '@models/TemplateObject';
import {
  IMap, IStepperContext, IProps, useValidator as useValidatorBase,
} from '../StepperCommon';

export type { IMap, IProps };

export interface ITemplateStepperContext extends IStepperContext {
  // Settings
  templates: TemplateObject[];
  // Data
  selectedTemplate: string;
  setSelectedTemplate: (selectedTemplate: string) => void;
  applicationName: string;
  setApplicationName: (applicationName: string) => void;
  selectedFolder: string[];
  setSelectedFolder: (selectedFolder: string[]) => void;
  newFolder: string;
  setNewFolder: (newFolder: string) => void;
}

export const StepperContext = createContext<ITemplateStepperContext>({ success: false, activeStep: 0 } as ITemplateStepperContext);

export function useStepperContext(): ITemplateStepperContext {
  return useContext(StepperContext);
}

export function useValidator(props: IProps, validator: (enable?: boolean) => boolean, deps?: DependencyList) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useValidatorBase(useStepperContext(), props, validator, deps);
}
