/* eslint-disable import/prefer-default-export */
enum ObjectType {
  NotSet = 0,
  Document = 1,
  Folder = 2,
  ReviewSet = 3,
  Rule = 4,
  KView = 5,
  Workflow = 6,
  WfInstance = 7,
  TaskList = 8,
  User = 9,
  Group = 10,
  Partition = 11,
  Form = 14,
  FormInstance = 15,
  Category = 17,
  DropDown = 18,
  Profile = 19,
  DBConnection = 20,
  Report = 21,
  Goal = 22,
  BusinessValue = 23,
  Chart = 24,
  Dashboard = 25,
  ChartSeries = 26,
  ML = 27,
  StreamAction = 28,
  DataList = 29,
  TreeActiveWorkflows = 31,
  TreeInactiveWorkflows = 32,
  TreeFormInstances = 33,
  TreeRunKview = 34,
  TreeCaseInstances = 35,
  Attribute = 36,
  FormControl = 37,
  WorkflowStep = 38,
  WorkflowBranch = 39,
  WorkflowStepUser = 40,
  WfStepInst = 41,
  WfStepUserInst = 42,
  FormCT = 43,
  WorkflowCT = 44,
  RuleCT = 45,
  Script = 46,
  ContentObject = 47,
  RuleValue = 48,
  ADSync = 49,
  ObjectReferences = 50,
  ObjectFormReferences = 51,
  ObjectWfReferences = 52,
  ObjectWorkflows = 53,
  FormEmail = 54,
  Timeline = 55,
  Project = 55,
  ProjectActivity = 56,
  ProjectActivityUser = 57,
  ProjectActivityNotify = 58,
  ProjectActivityDepend = 59,
  ProjectInstance = 60,
  ProjectActivityInst = 61,
  ProjectActivityUserInst = 62,
  TreeActiveProjects = 63,
  TreeInactiveProjects = 64,
  ObjectProjectReferences = 65,
  ProjectCT = 66,
  Process = 67,
  GenericProcessDefinition = 67,
  KViewColumn = 68,
  KViewFilter = 69,
  SharePointDS = 70,
  DatabaseDS = 71,
  ProjectResult = 72,
  Case = 73,
  CaseInstance = 74,
  Parm = 75,
  ChartField = 76,
  BusinessValueProperty = 77,
  DrilldownTarget = 78,
  ChartDataSource = 79,
  TreeRunDashboard = 80,
  GoalAction = 81,
  CaseProperty = 82,
  RuleValueCondition = 83,
  ChartSeriesCondition = 84,
  Image = 85,
  Log = 86,
  LogEvent = 87,
  IOT_DS = 88,
  AppEvent = 89,
  ActivityLog = 90,
  FormLock = 91,
  Application = 92,
}

export default ObjectType;
