// Them
import React from 'react';
import { styled, StyledComponentProps } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import prettyBytes from 'pretty-bytes';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

// Us
import { basePath } from '@components/utilities/Paths';
import { getAsync, ErrorResult } from '@components/data/rest';
import SystemInformation from '@models/SystemInformation';
import UserInformation from '@models/UserInformation';
import StorageInformation from '@models/StorageInformation';
import LicenseInformation from '@models/LicenseInformation';
import SystemResources from '@models/SystemResources';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: theme.typography.fontWeightRegular,
}));

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 700,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

interface SystemInfoMap {
  [name: string]: any;
}

interface State {
  systemInformation: SystemInformation;
  userInformation: UserInformation;
  storageInformation: StorageInformation;
  licenseInformation: LicenseInformation;
  systemResources: SystemResources;
}

interface DisplayValue {
  friendlyName?: string;
  converter?(value: any): string;
}

// Use only for names that need customized text (the auto-conversion is unacceptable) or
// for values where conversion is not straightforward (like dates and values representing bytes).
const nameMap: {[name: string]: DisplayValue} = {
  // System Information
  version: { friendlyName: 'Version' },
  buildNumber: { friendlyName: 'Build Number' },
  startedAt: { friendlyName: 'Process Director Started At', converter: (v) => new Date(Date.parse(v as string)).toString() },
  workflowTimelineDefinitions: { friendlyName: 'Workflow/Timeline Definitions' },
  workflowTimelineInstances: { friendlyName: 'Workflow/Timeline Instances' },
  activeWorkflowTimelineInstances: { friendlyName: 'Active Workflow/Timeline Instances' },
  // User and Submission Info
  dayPasses: { friendlyName: 'Day Passes' },
  formSubmissionPasses: { friendlyName: 'Number of Form Submission Passes' },
  dayPassesCount: { friendlyName: 'Number of Day Passes' },
  anonymousPassesUsed: { friendlyName: 'Passes Used (anonymous)' },
  authenticatedPassesUsed: { friendlyName: 'Passes Used (authenticated)' },
  nonAuthenticatedTimelineTasks: { friendlyName: 'Non-Authenticated Timeline Tasks' },
  nonAuthenticatedWorkflowTasks: { friendlyName: 'Non-Authenticated Workflow Tasks' },
  // Storage Information
  totalDiskLicensed: { converter: (v) => prettyBytes(v) },
  totalDiskUsed: { converter: (v) => prettyBytes(v) },
  fileSystemBlobSize: { friendlyName: 'BLOB Size on File System', converter: (v) => prettyBytes(v) },
  databaseSize: { friendlyName: 'DB Size', converter: (v) => prettyBytes(v) },
  databaseSizeWithoutLog: { friendlyName: 'DB Size (without Log)', converter: (v) => prettyBytes(v) },
  databaseMaxSize: { friendlyName: 'DB Max Size', converter: (v) => prettyBytes(v) },
  databaseMaxSizeWithoutLog: { friendlyName: 'DB Max Size (without Log)', converter: (v) => prettyBytes(v) },
  databaseLogSize: { friendlyName: 'DB Log Size', converter: (v) => prettyBytes(v) },
  databaseLogMaxSize: { friendlyName: 'DB Log Max Size', converter: (v) => prettyBytes(v) },
  freeSpace: { converter: (v) => prettyBytes(v) },
  // License Data
  pdf: { friendlyName: 'PDF' },
  conceptShareAnnotationOption: { friendlyName: 'ConceptShare Annotation Option' },
  processIntelligenceAndAdvancedReporting: { friendlyName: 'Process Intelligence and Advanced Reporting' },
  importingAndScanning: { friendlyName: 'Importing and Scanning' },
  samlAuthentication: { friendlyName: 'SAML Authentication' },
  sdk: { friendlyName: 'SDK' },
  sharePointIntegration: { friendlyName: 'SharePoint Integration' },
  msOfficeIntegration: { friendlyName: 'MS Office Integration' },
  aiMachineLearning: { friendlyName: 'AI/Machine Learning Enabled' },
  nonAuthenticatedUserTaskAssignment: { friendlyName: 'Non-Authenticated User Task Assignment' },
  // System Resources
  pagedMemory: { converter: (v) => prettyBytes(v) },
  pagedSystemMemory: { converter: (v) => prettyBytes(v) },
  virtualMemory: { converter: (v) => prettyBytes(v) },
};

class SystemInformationUI extends
  React.Component<StyledComponentProps, State> {
  private static convertForDisplay(name: string, value: any): React.ReactFragment {
    const result = nameMap[name]?.converter?.(value);
    if (result !== undefined) {
      return (<>{result}</>);
    }

    switch (typeof value) {
      case 'object':
        if (Array.isArray(value)) {
          // eslint-disable-next-line react/no-array-index-key
          return (<>{value.map((v, i) => <div key={`k${i}`}>{v}</div>)}</>);
        }
        return (<>{value?.toString() ?? ''}</>);
      case 'boolean':
        return (<>{value as boolean === true ? 'Yes' : 'No'}</>);
      default:
        return (<>{value?.toString() ?? ''}</>);
    }
  }

  private static getDisplayName(name: string): string {
    if (nameMap[name] === undefined || nameMap[name].friendlyName === undefined) {
      const result = name.replace(/([A-Z]|[0-9]+)/g, ' $1');
      return result.charAt(0).toUpperCase() + result.slice(1);
    }

    return nameMap[name]?.friendlyName || ''; // eslint doesn't seem to notice checks above.
  }

  public constructor(props: any) {
    super(props);
    this.state = {
      systemInformation: {} as SystemInformation,
      userInformation: {} as UserInformation,
      storageInformation: {} as StorageInformation,
      licenseInformation: {} as LicenseInformation,
      systemResources: {} as SystemResources,
    };

    // Add CSS on-the-fly. We don't want this in newer 100% react pages.
    // eslint-disable-next-line no-restricted-globals
    const link = document.createElement('link');
    link.href = `${basePath}css/bpw.css?ver=${Math.floor(Date.now() / (60 * 60000))}`;
    link.rel = 'stylesheet';

    document.getElementsByTagName('head')[0].appendChild(link);
  }

  public async componentDidMount(): Promise<void> {
    // Retrieving the 5 different info items in parallel makes the UI snappier but there
    // is a race condition if there's any errors. We generally don't care and will report
    // the last one encountered. They all get logged to the dev console.
    let error = false;
    let errorMsg = 'Unexpected server error';
    const errorHandler = async (errorResult: ErrorResult) => {
      error = true;
      errorMsg = errorResult.errorMessage;
      errorResult.log();
      return true;
    };
    await Promise.all([
      getAsync('SystemInformation/SystemInformation', async (response) => this.setState({ systemInformation: await response.obj<SystemInformation>() }), errorHandler),
      getAsync('SystemInformation/UserSubmissionInformation', async (response) => this.setState({ userInformation: await response.obj<UserInformation>() }), errorHandler),
      getAsync('SystemInformation/StorageInformation', async (response) => this.setState({ storageInformation: await response.obj<StorageInformation>() }), errorHandler),
      getAsync('SystemInformation/LicenseInformation', async (response) => this.setState({ licenseInformation: await response.obj<LicenseInformation>() }), errorHandler),
      getAsync('SystemInformation/SystemResources', async (response) => this.setState({ systemResources: await response.obj<SystemResources>() }), errorHandler),
    ]);
    if (error) {
      enqueueSnackbar(errorMsg, {
        variant: 'error',
        preventDuplicate: true,
        persist: true,
        action: (key) => (
          <Button size="small" style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
            Dismiss
          </Button>
        ),
      });
    }
  }

  public render() {
    const {
      systemInformation,
      userInformation,
      storageInformation,
      licenseInformation: licenseInfo,
      systemResources: systemResourcesInfo,
    } = this.state;
    const sysInfo = systemInformation as SystemInfoMap;
    const userSubInfo = userInformation as SystemInfoMap;
    const storageInfo = storageInformation as SystemInfoMap;
    const licInfo = licenseInfo as SystemInfoMap;
    const sysResInfo = systemResourcesInfo as SystemInfoMap;

    return (
      <Root>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Heading>System Information</Heading>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <StyledTable size="small" aria-label="customized table">
                <TableBody>
                  {Object.keys(sysInfo).map((name) => (
                    <StyledTableRow key={name}>
                      <StyledTableCell component="th" scope="row">
                        {SystemInformationUI.getDisplayName(name)}
                      </StyledTableCell>
                      <StyledTableCell align="right">{SystemInformationUI.convertForDisplay(name, sysInfo[name])}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Heading>User and Submission Information</Heading>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <StyledTable size="small" aria-label="customized table">
                <TableBody>
                  {Object.keys(userSubInfo).map((name) => (
                    <StyledTableRow key={name}>
                      <StyledTableCell component="th" scope="row">
                        {SystemInformationUI.getDisplayName(name)}
                      </StyledTableCell>
                      <StyledTableCell align="right">{SystemInformationUI.convertForDisplay(name, userSubInfo[name])}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Heading>Storage Information</Heading>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <StyledTable size="small" aria-label="customized table">
                <TableBody>
                  {Object.keys(storageInfo).map((name) => (
                    <StyledTableRow key={name}>
                      <StyledTableCell component="th" scope="row">
                        {SystemInformationUI.getDisplayName(name)}
                      </StyledTableCell>
                      <StyledTableCell align="right">{SystemInformationUI.convertForDisplay(name, storageInfo[name])}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Heading>License Information</Heading>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <StyledTable size="small" aria-label="customized table">
                <TableBody>
                  {Object.keys(licInfo).map((name) => (
                    <StyledTableRow key={name}>
                      <StyledTableCell component="th" scope="row">
                        {SystemInformationUI.getDisplayName(name)}
                      </StyledTableCell>
                      <StyledTableCell align="right">{SystemInformationUI.convertForDisplay(name, licInfo[name])}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Heading>System Resources</Heading>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <StyledTable size="small" aria-label="customized table">
                <TableBody>
                  {Object.keys(sysResInfo).map((name) => (
                    <StyledTableRow key={name}>
                      <StyledTableCell component="th" scope="row">
                        {SystemInformationUI.getDisplayName(name)}
                      </StyledTableCell>
                      <StyledTableCell align="right">{SystemInformationUI.convertForDisplay(name, sysResInfo[name])}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Root>
    );
  }
}

export default SystemInformationUI;
