import { createContext, useContext, DependencyList } from 'react';
import Icon, { IconType } from '@models/Icon';
import ObjectType from '@models/ObjectType';
import { FormDefTypes } from '@models/CreateFormDef';
import { ReportTypes } from '@models/CreateReport';
import {
  IMap, IStepperContext,
  IProps, useValidator as useValidatorBase,
} from '../StepperCommon';

export type { IMap, IProps };
export interface IObject {
  name: string;
  type: ObjectType;
  description: string;
  icon: Icon;
  endpoint: string;
}

export const objects: IObject[] = [
  {
    name: 'Business Rule',
    type: ObjectType.Rule,
    description: 'Business Rules are reusable objects that can be embedded within your applications to conditionally control how they should run and behave in various conditions.',
    icon: { type: IconType.OgIcon, data: { id: 3001, color: 'white' } },
    endpoint: 'BusinessRule',
  },
  {
    name: 'Business Value',
    type: ObjectType.BusinessValue,
    description: 'Business Values enable you to retrieve and virtualize data from external sources for use anywhere in Process Director.',
    icon: { type: IconType.OgIcon, data: { id: 3002, color: 'white' } },
    endpoint: 'BusinessValue',
  },
  {
    name: 'Case Definition',
    type: ObjectType.Case,
    description: 'Case Definitions specify the properties for a Case Management application. They create a shared data context that is passed between all the objects used in the Case.',
    icon: { type: IconType.OgIcon, data: { id: 3003, color: 'white' } },
    endpoint: 'CaseDefinition',
  },
  {
    name: 'Chart',
    type: ObjectType.Chart,
    description: 'Charts displays a graphical representation of values from Knowledge Views and Business Values to enable easier dashboarding.',
    icon: { type: IconType.OgIcon, data: { id: 3004, color: 'white' } },
    endpoint: 'Chart',
  },
  {
    name: 'Dashboard',
    type: ObjectType.Dashboard,
    description: 'Dashboards enable you to create a custom screen that displays existing Charts, Knowledge Views, Forms, and other objects.',
    icon: { type: IconType.OgIcon, data: { id: 3005, color: 'white' } },
    endpoint: 'Dashboard',
  },
  {
    name: 'Datasource',
    type: ObjectType.DBConnection,
    description: 'Datasources create a connection to an external database to retrieve data, usually via a Business Value.',
    icon: { type: IconType.OgIcon, data: { id: 3006, color: 'white' } },
    endpoint: 'Datasource',
  },
  {
    name: 'Document / File',
    type: ObjectType.Document,
    description: 'Enables you to upload electronic files to the Content List for storage or use in Applications.',
    icon: { type: IconType.OgIcon, data: { id: 3019, color: 'white' } },
    endpoint: 'File',
  },
  {
    name: 'Dropdown Object',
    type: ObjectType.DropDown,
    description: 'Dropdown Objects are reusable lists that populate the options available in Dropdown or Listbox Form controls.',
    icon: { type: IconType.OgIcon, data: { id: 3008, color: 'white' } },
    endpoint: 'Dropdown',
  },
  {
    name: 'Folder',
    type: ObjectType.Folder,
    description: 'The option enables you to create a new folder in the Content List.',
    icon: { type: IconType.OgIcon, data: { id: 3009, color: 'white' } },
    endpoint: 'Folder',
  },
  {
    name: 'Form Definition',
    type: ObjectType.Form,
    description: 'Electronic Forms are the primary means by which users submit information to the system, and consist of Form Fields that users can fill out or edit to supply the data for your application.',
    icon: { type: IconType.OgIcon, data: { id: 3010, color: 'white' } },
    endpoint: 'FormDefinition',
  },
  {
    name: 'Goal',
    type: ObjectType.Goal,
    description: 'Goals are used to set a global state in the Process Director system and, optionally, to perform actions based on the state. Goals can be evaluated manually, or on a scheduled basis.',
    icon: { type: IconType.OgIcon, data: { id: 3011, color: 'white' } },
    endpoint: 'Goal',
  },
  // { // Moved to ImportPdz.tsx stepper.
  //   name: 'Import Objects',
  //   type: -1, // No value exists
  //   description: 'This feature enables you to import application objects exported from another Process Director system.',
  //   icon: { type: IconType.OgIcon, data: { id: 3020, color: 'white' } },
  // },
  {
    name: 'Knowledge View',
    type: ObjectType.KView,
    description: 'Knowledge Views display all information stored in Process Director, such as Forms, Process Timelines, Attachments, documents, etc. They provide the basic reporting functions for the system.',
    icon: { type: IconType.OgIcon, data: { id: 3013, color: 'white' } },
    endpoint: 'KnowledgeView',
  },
  {
    name: 'Machine Learning Definition',
    type: ObjectType.ML,
    description: 'ML Definitions enable you to use Process Director&apos;s Artificial Intelligence capabilities to review a dataset, and make predictions based on the state of that dataset.',
    icon: { type: IconType.OgIcon, data: { id: 3014, color: 'white' } },
    endpoint: 'MLDefinition',
  },
  {
    name: 'Process Timeline',
    type: ObjectType.Timeline,
    description: 'Process Timelines are the primary process automation component of Process Director, and model the actions and notifications that will occur during the course of the process.',
    icon: { type: IconType.OgIcon, data: { id: 3015, color: 'white' } },
    endpoint: 'Timeline',
  },
  {
    name: 'Report',
    type: ObjectType.Report,
    description: 'Reports are generated via an advanced reporting component that enables you to generate complex reports and infographics from information stored in Process Director.',
    icon: { type: IconType.OgIcon, data: { id: 3016, color: 'white' } },
    endpoint: 'Report',
  },
  {
    name: 'Stream Action',
    type: ObjectType.StreamAction,
    description: 'Stream Actions read a recordset stream from a Datasource, and automatically submit a Form instance for each record in the stream. Stream Actions can be invoked manually or on a scheduled basis.',
    icon: { type: IconType.OgIcon, data: { id: 3017, color: 'white' } },
    endpoint: 'StreamAction',
  },
];

export interface ICreateObjectStepper extends IStepperContext {
  // Data
  //    PickObject
  selectedObject: string;
  setSelectedObject: (selectedObject: string) => void;
  objectName: string;
  setObjectName: (objectName: string) => void;
  formDefOptions: {
    selected: FormDefTypes;
    setSelected: (selected: FormDefTypes) => void;
  }
  reportOptions: {
    selected: ReportTypes;
    setSelected: (selected: ReportTypes) => void;
  }
  fileOptions: {
    selectedFile: File | null;
    setSelectedFile: (selectedFile: File) => void;
  }
  //    PickTemplate
  selectedFolder: string[];
  setSelectedFolder: (selectedFolder: string[]) => void;
}

export const StepperContext = createContext<ICreateObjectStepper>({ success: false, activeStep: 0 } as ICreateObjectStepper);

export function useStepperContext(): ICreateObjectStepper {
  return useContext(StepperContext);
}

export function useValidator(props: IProps, validator: (enable?: boolean) => boolean, deps?: DependencyList) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useValidatorBase(useStepperContext(), props, validator, deps);
}
