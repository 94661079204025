// Them
import { History } from 'react-router-dom';

// Us
import { schemeServerBasePath, getReactUrl } from '@components/utilities/Paths';
import Icon from '@models/Icon';
import { getIconData } from '@components/layout/Icon';
import Swal from 'sweetalert2';
import { logger } from '@components/utilities/logger';

export interface IDesktopItem {
  Height?: number;
  HoverTitle?: boolean;
  HTMLContent?: string;
  ItemAction?: number;
  ItemURL?: string;
  ItemID: string;
  ItemIcon?: number,
  ItemIconColor?: string,
  ItemName?: string,
  Left?: number,
  Tooltip?: string,
  Top?: number,
  Width?: number,
  WindowState?: number;
}

export interface IContentFrame {
  openUrl?(desktopItem: IDesktopItem): void;
  eval(code: string): void;
}

export function openFormPopup(url: string) {
  // eslint-disable-next-line no-restricted-globals
  const winOptions = `,status=no,scrollbars=yes,resizable=yes,top=0,left=0,width=${screen.width - 18},height=${screen.height - 100}`;
  window.open(url, '_blank', winOptions);
}

/**
 * Use for general navigation where you want content displayed in embedded iframe, ignoring current workspace.
 * @see {@link navWorkspace()} for workspace-based navigation.
 * @note You should attempt to use react-router-dom's &lt;Link&gt; component over this, if possible.
 * Search the source for examples.
 * @param history History object from react-router-dom.
 * @param url URL to navigate to. URL should be the true absolute path on server.
 */
export function navContent(history: History<unknown>, url: string, options?: { makeUnique?: boolean }) {
  const contentUrl = url.startsWith('/') ? new URL(`/if${url}`, schemeServerBasePath) : new URL(`/if/${url}`, schemeServerBasePath);
  if (options?.makeUnique) {
    contentUrl.searchParams.set('ver', (Math.random() + 1).toString(36).substring(5));
  }
  const urlFinal = contentUrl.pathname + contentUrl.search;
  if ((window as any).bpChanged) {
    confirmationDialog().then((result) => {
      if (result.isConfirmed) {
        (window as any).bpChanged = false;
        history.push(urlFinal);
      }
    });
  } else {
    history.push(urlFinal);
  }
}

function isAbsoluteUrl(url: string): boolean {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}

function waitForBpWindow(callback: () => void, intervalTime = 100) {
  const intervalId = setInterval(() => {
    const iframe = document.querySelector('iframe[title="content"]') as HTMLIFrameElement;
    const bpWindow = (iframe?.contentWindow as unknown as IContentFrame | null);

    if (bpWindow?.openUrl !== undefined && bpWindow.openUrl !== null) {
      clearInterval(intervalId);
      logger.debug('bpWindow found');
      callback();
    }
  }, intervalTime);
}

/**
 * Use for navigation that respects current workspace, if possible. For example, for desktop
 * workspaces, URL is opened inside a desktop workspace window.
 * @see {@link navContent()} for general navigation.
 * @param url URL to navigate to. URL should be the true absolute path on server.
 * @param name Optional name or title to be used for the window (primarily for desktop workspaces).
 * @param options Options for how to navigate.
 */
export function navWorkspace(history: History<unknown>, url: string, name?: string, options?: { id?: string, icon?: Icon, useContentFrame?: boolean, checkFormCase?: boolean, makeUnique?: boolean }, isDesktop?: boolean) {
  if ((window as any).bpChanged) {
    confirmationDialog().then((result) => {
      if (result.isConfirmed) {
        (window as any).bpChanged = false;
        if (isDesktop) {
          waitForBpWindow(() => proceed());
        } else {
          proceed();
        }
      }
    });
  } else if (isDesktop) {
    waitForBpWindow(() => proceed());
  } else {
    proceed();
  }

  function proceed() {
    const iframe = document.querySelector('iframe[title="content"]') as HTMLIFrameElement;
    const bpWindow = (iframe?.contentWindow as unknown as IContentFrame | null);

    if (bpWindow?.openUrl && !options?.useContentFrame) {
      const icon = getIconData(options?.icon);
      const desktopItem: IDesktopItem = {
        Height: -1,
        HoverTitle: false,
        ItemAction: 16,
        ItemURL: url,
        ItemID: options?.id || '',
        ItemIcon: icon?.id || 0,
        ItemIconColor: icon?.color || '',
        ItemName: name ?? '',
        Left: -1,
        Tooltip: '',
        Top: -1,
        Width: -1,
        WindowState: 0,
      };
      bpWindow.openUrl(desktopItem);
    } else if (isAbsoluteUrl(url)) {
      window.location.href = url;
    } else {
      const contentUrl = getReactUrl(`/if${url}`, { makeUnique: options?.makeUnique });
      const urlFinal = contentUrl.pathname + contentUrl.search;
      // Legacy quirks behavior, if the url specifically has lower case "f" in form.aspx, open in a popup.
      if (options?.checkFormCase && urlFinal.includes('/form.aspx')) {
        openFormPopup(url);
      } else {
        history.push(`/if${url}`);
      }
    }
  }
}

function confirmationDialog() {
  return Swal.fire({
    title: 'Confirm',
    text: 'You will lose your changes unless you save them. To save your changes, click Cancel, and the Update or OK button.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Proceed',
    cancelButtonText: 'Cancel',
  });
}
