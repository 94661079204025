// Them
import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';
import Skeleton from '@mui/material/Skeleton';

// Us
import { basePath } from '@components/utilities/Paths';
import Icon, { IconType, OgIconData } from '@models/Icon';
import DisplayOptions from '@models/DisplayOptions';
import { FontLoadingContext } from '@components/layout/FontLoadingContext';

const useStyles = makeStyles((theme: Theme) => createStyles({
  icon: {
    verticalAlign: 'middle',
  },
  'icon-xl': {
    fontSize: '3rem',
  },
  'icon-lg': {
    fontSize: '2rem',
  },
  'icon-md': {
    fontSize: '1rem',
    marginRight: '.3125rem',
    width: '1rem',
  },
  iconImg: {
    borderWidth: '0rem',
    height: '1rem',
    width: '1rem',
    textAlign: 'center',
    verticalAlign: 'middle',
    marginRight: '.3125rem',
  },
}));

const getOGIcon = (id: number) => {
  if (id > 0) {
    return `images/icons/${id}.gif`;
  }
  // Icon numbers < 0 are custom icons
  if (id < 0) {
    return `custom/icons/${-id}.gif`;
  }
  // 0 icons mean return the "no icon"
  return 'images/icons/0.gif';
};

const materialIcons : {[id: number] : string} = {
  3001: 'filter_alt',
  3002: 'dataset',
  3003: 'work',
  3004: 'leaderboard',
  3005: 'dashboard',
  3006: 'warehouse',
  3007: 'description',
  3008: 'arrow_drop_down_circle',
  3009: 'folder',
  3010: 'list_alt',
  3011: 'mode_standby',
  3012: 'swap_vert',
  3013: 'search',
  3014: 'science',
  3015: 'view_timeline',
  3016: 'pie_chart',
  3017: 'bolt',
  3018: 'settings',
  3019: 'upload_file',
  3020: 'publish',
};

interface OwnProps {
  icon: Icon,
  tooltip?: string,
  variant?: string,
  color?: string,
  style?: object,
}

export default function CustomIcon(props: OwnProps) {
  const {
    icon, tooltip, variant, color, style,
  } = props;
  const classes = useStyles();
  const fontLoaded = React.useContext(FontLoadingContext);
  const vname = variant ? `icon-${variant}` : 'icon-md';
  const iconType = Number.isNaN(parseInt(icon.type.toString(), 10)) ? IconType[icon.type.toString() as keyof typeof IconType] : parseInt(icon.type.toString(), 10);
  switch (iconType) {
    case IconType.OgIcon: {
      const iconData = icon.data as OgIconData;
      const iconColor = color || iconData.color;
      if (!fontLoaded) {
        return <Skeleton component="span" title={tooltip} className={clsx(classes.icon, classes[vname], 'material-icons')} variant="rounded" sx={{ display: 'inline-block' }} />;
      }
      if (iconData.id > 3000) {
        return (
          <span title={tooltip} className={clsx(classes.icon, classes[vname], 'material-icons')} style={{ color: iconColor, ...style }}>
            {materialIcons[iconData.id]}
          </span>
        );
      } if (iconData.id > 2000 && iconData.id < 3000) {
        return <span title={tooltip} className={clsx(classes.icon, classes[vname], 'fa', `bpfai-${iconData.id % 1000}`)} style={{ color: iconColor, ...style }} />;
      } if (iconData.id > 1000) {
        return <span title={tooltip} className={clsx(classes.icon, classes[vname], 'glyphicons', `bpglyph-${iconData.id % 1000}`)} style={{ color: iconColor, ...style }} />;
      }
      return <img className={classes.iconImg} src={`${basePath}${getOGIcon(iconData.id)}`} alt={tooltip} />;
    }
    default:
  }
  return null;
}

export function showIcon(displayOptions: DisplayOptions): boolean {
  switch (DisplayOptions[displayOptions.toString() as keyof typeof DisplayOptions]) {
    case DisplayOptions.Icon:
    case DisplayOptions.Both:
      return true;
    default:
      return false;
  }
}

export function getIconData(icon?: Icon): OgIconData | null {
  switch (IconType[icon?.type.toString() as keyof typeof IconType]) {
    case IconType.OgIcon: {
      return icon?.data as OgIconData;
    }
    default:
      return null;
  }
}
