/* eslint-disable no-bitwise */
// Them
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import CardActions from '@mui/material/CardActions';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/system/Box';
import { useTheme } from '@mui/styles';
import moment from 'moment';
import rfdc from 'rfdc';

// Us
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { logger } from '@components/utilities/logger';
import { getAsync } from '@components/data/rest';
import TemplateObject from '@models/TemplateObject';
import { parseEnumValue } from '@components/utilities/enums';
import { getEnumResource } from '@components/utilities/Resources';
import OverflowTip from '@components/layout/OverflowTip';
import { useContentList } from '@store/contentListSlice';
import { useUserProfile, setDefaultPageAsync } from '@store/userProfileSlice';
import ContentItem from '@models/ContentItem';
import { reactPath } from '@components/utilities/Paths';
import { CustomTheme, useResponsiveToolbarHeight } from '@components/layout/Theme';
import Icon from '@components/layout/Icon';
import MessageBox from '@components/layout/MessageBox';
import DefaultPage from '@models/DefaultPage';
import { ContentListWidgetStateContext } from '@components/layout/ContentListWidgetState';
import FromTemplateDialog from './Home/FromTemplate/FromTemplateDialog';
import CreateObjectDialog from './Home/CreateObject/CreateObjectDialog';
import ImportPdzDialog from './Home/ImportPdz/ImportPdzDialog';

function buildMru(mru: ContentItem[], next: ContentItem, currentUser: string) {
  if (next.modifiedByUID === currentUser) {
    mru.push(next);
  }
  mru.sort((a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime());

  if (mru.length > 6) {
    // eslint-disable-next-line no-param-reassign
    mru.length = 6;
  }
  if (next.children) {
    next.children.forEach((item) => buildMru(mru, item, currentUser));
  }
}

function buildFavorites(favorites: ContentItem[], next: ContentItem) {
  if (next.favorite) {
    favorites.push(next);
  }
  favorites.sort((a, b) => a.name.localeCompare(b.name));

  if (favorites.length > 100) {
    // eslint-disable-next-line no-param-reassign
    favorites.length = 100;
  }
  if (next.children) {
    next.children.forEach((item) => buildFavorites(favorites, item));
  }
}

const card = (image: string, heading: string, body: string, onCreate?: () => void, helpLink?: string, altBtnName?: string) => (
  <Card
    elevation={3}
    sx={{
      cursor: 'default', minHeight: '22rem', display: 'flex', flexDirection: 'column',
    }}
  >
    <CardMedia
      component="img"
      image={`${reactPath}/${image}.svg`}
      alt="New Form"
    />
    <CardContent sx={{ paddingBottom: 0, minHeight: '4.5rem' }}>
      <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
        {heading}
      </Typography>
      <Typography variant="body2" sx={{ fontSize: 14 }}>
        {body}
      </Typography>
    </CardContent>
    <CardActions sx={{ marginTop: 'auto', alignSelf: 'flex-end' }}>
      <Button size="small" onClick={() => onCreate?.()}>{altBtnName || 'Create'}</Button>
      <Button size="small" href={`https://doc.bplogix.com/content/redirect_help.aspx?control=${helpLink}`} target="_blank" rel="noopener noreferrer" sx={{ marginLeft: '.5rem' }}>
        <OpenInNewIcon fontSize="small" sx={{ mr: '.1rem' }} />
        Learn More
      </Button>
    </CardActions>
  </Card>
);

const miniCard = (item: ContentItem) => (
  <Grid key={item.id} xs={1} sx={{ padding: '1rem 1rem 1rem 0' }}>
    <Box
      sx={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}
    >
      <Link to={`/if${item.url}`} component={undefined}>
        <ButtonBase sx={{
          objectFit: 'cover',
          borderRadius: '10px',
          overflow: 'hidden',
          width: '90px',
          height: '90px',
          cursor: 'pointer',
          alignSelf: 'center',
          backgroundColor: (theme) => theme.bpcustom.chromeBackground,
        }}
        >
          <Icon icon={item.icon} color="white" variant="xl" />
        </ButtonBase>
      </Link>
      <Box sx={{ marginLeft: '.33rem', minWidth: 0 }}>
        <OverflowTip
          tooltip={item.name}
          sx={{
            fontSize: 14,
            fontWeight: 600,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {item.name}
        </OverflowTip>
        <Typography color="text.secondary" variant="body2" sx={{ fontSize: 12, fontWeight: 600, mb: '.33rem' }}>
          {getEnumResource('OBJTYPE', item.type.toString())}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: 11, whiteSpace: 'pre-line' }}>
          {`Modified ${moment(item.modified).fromNow()}`}
          {item.modifiedBy && ` by ${item.modifiedBy}`}
        </Typography>
      </Box>
    </Box>
  </Grid>
);

enum RunningDialog {
  None = 0,
  CreateObject = 1,
  CreateFromTemplate = 2,
  ImportPdz = 3,
  NoTemplates = 4,
  PickDefaultPage = 5,
}

export default function Home() {
  const dispatch = useDispatch();
  const contentList = useContentList();
  const userProfile = useUserProfile();
  const theme = useTheme() as CustomTheme;
  const { open, selectedId, setSelectedId } = useContext(ContentListWidgetStateContext);
  const [runningDialog, setRunningDialog] = useState<RunningDialog>(RunningDialog.None);
  const [mruList, setMruList] = useState<ContentItem[]>([]);
  const [favoritesList, setFavoritesList] = useState<ContentItem[]>([]);
  const [templates, setTemplates] = useState<TemplateObject[] | undefined>(undefined);
  const [templateError, setTemplateError] = useState('');
  const isDevStaging = ((userProfile?.defaultPage ?? DefaultPage.Home) & DefaultPage.Production) === 0;
  const toolbarHeight = useResponsiveToolbarHeight();
  const columns = isDevStaging ? {
    xs: 1, sm: 2, md: 2, lg: 3, xl: 3,
  } : {
    xs: 1, sm: 1, md: 1, lg: 1, xl: 1,
  };
  const widths = isDevStaging ? {
    width: '60%',
    [theme.breakpoints.down('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  } : {
    width: '20%',
    [theme.breakpoints.down('lg')]: {
      width: '35%',
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  };

  // Reset selectedId for the content list because this page is not in it.
  useEffect(() => {
    if (selectedId) {
      logger.debug('Home: Resetting selectedId');
      setSelectedId('');
    }
  }, [selectedId, setSelectedId]);

  useEffect(() => {
    getAsync('Applications/Templates',
      async (response) => { setTemplateError(''); setTemplates(await response.obj<TemplateObject[]>()); },
      async (error) => {
        if (error.httpStatusCode === 404) {
          setTemplateError('There are no templates available to create from. Please visit the Process Director support site to download templates.');
          return true;
        }
        setTemplateError('Templates are not available at this time. Please try again later.');
        return false;
      });
  }, []);

  useEffect(() => {
    if (contentList && contentList.length !== 0) {
      const clCopyMru = rfdc()(contentList);
      const mru = new Array<ContentItem>();
      clCopyMru.map((partition:any) => buildMru(mru, partition, userProfile?.userUId || ''));
      setMruList(mru);
      const clCopyFav = rfdc()(contentList);
      const favorites = new Array<ContentItem>();
      clCopyFav.map((partition:any) => buildFavorites(favorites, partition));
      setFavoritesList(favorites);
    }
  }, [contentList, userProfile?.userUId]);

  useEffect(() => {
    const defaultPage = parseEnumValue(userProfile?.defaultPage?.toString() ?? DefaultPage.Home.toString(), DefaultPage);
    if (defaultPage === DefaultPage.Unset) {
      setRunningDialog(RunningDialog.PickDefaultPage);
    }
  }, [userProfile?.defaultPage]);

  const handleDefaultPageMsg = (defaultPage: DefaultPage) => {
    setRunningDialog(RunningDialog.None);
    // Preserve whether home page uses production-style or not.
    // eslint-disable-next-line no-param-reassign
    defaultPage |= parseEnumValue(userProfile?.defaultPage?.toString() ?? DefaultPage.Home.toString(), DefaultPage) & DefaultPage.Production;
    setDefaultPageAsync(dispatch, defaultPage);
  };

  return (
    <>
      <div style={{ height: `${toolbarHeight}px` }} />
      <Box sx={{ overflow: 'auto', height: `calc(100vh - ${toolbarHeight}px)`, marginLeft: open ? '483px' : '' }}>
        <Typography
          component="div"
          sx={{
            flexGrow: 1, textAlign: 'center', marginTop: '1rem', marginBottom: '0rem', paddingBottom: 0, fontWeight: 600, fontSize: '20px',
          }}
        >
          Welcome. Choose an option to get started or open an existing project from the Content List.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', minWidth: '100%' }}>
          <Grid
            container
            spacing={3}
            columns={{
              ...columns,
            }}
            sx={{
              alignSelf: 'center',
              marginTop: 0,
              ...widths,
            }}
          >
            {isDevStaging && (
              <>
                <Grid xs={1} data-cy="CreateNew">
                  {card('newForm', 'Create New', 'Create a form, timeline, knowledge view, and more.', () => setRunningDialog(RunningDialog.CreateObject), 'CREATEOBJ')}
                </Grid>
                <Grid xs={1} data-cy="CreateFromTemplate">
                  {card('newTemplate', 'Create from a Template', 'Reusable objects that control how timelines should run and behave.', () => (templateError || !templates ? setRunningDialog(RunningDialog.NoTemplates) : setRunningDialog(RunningDialog.CreateFromTemplate)), 'CREATETMPL')}
                </Grid>
              </>
            )}
            <Grid xs={1} data-cy="ImportPDZ">
              {card('importPdz', 'Import', 'Import your work from other Process Director systems.', () => setRunningDialog(RunningDialog.ImportPdz), 'IMPORTPDZ', 'Import')}
            </Grid>
          </Grid>
        </Box>
        {mruList.length > 0 && (
          <>
            <Box sx={{
              justifyContent: 'center', display: 'flex', alignItems: 'center',
            }}
            >
              <Box sx={{
                width: '80%',
                textAlign: 'left',
                [theme.breakpoints.down('lg')]: {
                  width: '100%', marginLeft: '1rem', marginRight: '1rem',
                },
              }}
              >
                <Typography
                  component="div"
                  sx={{
                    marginTop: '1rem',
                    marginBottom: '0rem',
                    paddingBottom: 0,
                    fontWeight: 600,
                    fontSize: '20px',
                  }}
                >
                  Recently Modified
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', minWidth: '100%' }}>
              <Grid
                container
                spacing={6}
                columns={{
                  xs: 1, sm: 2, md: 4, lg: 5, xl: 6,
                }}
                sx={{
                  alignSelf: 'center',
                  width: '80%',
                  marginTop: '0rem',
                  [theme.breakpoints.down('lg')]: {
                    width: '100%', marginLeft: '1rem', marginRight: '1rem',
                  },
                }}
                data-cy="RecentlyModifiedList"
              >
                {mruList.map((item) => miniCard(item))}
              </Grid>
            </Box>
          </>
        )}
        {favoritesList.length > 0 && (
          <>
            <Box sx={{
              justifyContent: 'center', display: 'flex', alignItems: 'center',
            }}
            >
              <Box sx={{
                width: '80%',
                textAlign: 'left',
                [theme.breakpoints.down('lg')]: {
                  width: '100%', marginLeft: '1rem', marginRight: '1rem',
                },
              }}
              >
                <Typography
                  component="div"
                  sx={{
                    marginTop: '1rem',
                    marginBottom: '0rem',
                    paddingBottom: 0,
                    fontWeight: 600,
                    fontSize: '20px',
                  }}
                >
                  Favorites
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', minWidth: '100%' }}>
              <Grid
                container
                spacing={6}
                columns={{
                  xs: 1, sm: 2, md: 4, lg: 5, xl: 6,
                }}
                sx={{
                  alignSelf: 'center',
                  width: '80%',
                  marginTop: '0rem',
                  [theme.breakpoints.down('lg')]: {
                    width: '100%', marginLeft: '1rem', marginRight: '1rem',
                  },
                }}
                data-cy="FavoritesList"
              >
                {favoritesList.map((item) => miniCard(item))}
              </Grid>

            </Box>
          </>
        )}
        {runningDialog === RunningDialog.CreateObject && (
          <CreateObjectDialog
            open={runningDialog === RunningDialog.CreateObject}
            onClose={() => setRunningDialog(RunningDialog.None)}
          />
        )}
        {runningDialog === RunningDialog.CreateFromTemplate && (
          <FromTemplateDialog
            open={runningDialog === RunningDialog.CreateFromTemplate}
            templates={templates}
            onClose={() => setRunningDialog(RunningDialog.None)}
          />
        )}
        {runningDialog === RunningDialog.ImportPdz && (
          <ImportPdzDialog
            open={runningDialog === RunningDialog.ImportPdz}
            onClose={() => setRunningDialog(RunningDialog.None)}
          />
        )}
        {runningDialog === RunningDialog.NoTemplates && (
          <MessageBox
            open={runningDialog === RunningDialog.NoTemplates}
            onClose={() => setRunningDialog(RunningDialog.None)}
            heading="No Templates Found"
            body={templateError}
            helpLink="NOTEMPLATES"
          />
        )}
        {runningDialog === RunningDialog.PickDefaultPage && (
          <MessageBox
            open={runningDialog === RunningDialog.PickDefaultPage}
            customButtons={[
              {
                label: 'Use Workspace',
                onClick: () => handleDefaultPageMsg(DefaultPage.Workspace),
              },
              {
                label: 'Keep Home Page',
                default: true,
                onClick: () => handleDefaultPageMsg(DefaultPage.Home),
              },
            ]}
            heading="Choose Default Page"
            body={[
              'Welcome to the Navigator 2.0 Home, your fast and easy way to find and create objects, applications, and more.',
              'If you’d rather see your active workspace by default, click Use Workspace. Otherwise, click Keep Home Page (recommended).',
              'You can change these options later in your Profile.',
            ]}
          />
        )}
      </Box>
    </>
  );
}
