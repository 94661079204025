// Them
import { Link } from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';
import IconButton from '@mui/material/IconButton';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/system';

// Us
import { CustomTheme } from '@components/layout/Theme';

function commonIconAndToggleStyle(theme: CustomTheme) {
  return ({
    '&': {
      marginRight: theme.spacing(0.5),
      border: 0,
      padding: 7,
      height: '32px',
      width: '32px',
      borderRadius: '50%',
      color: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hoverInverse,
      borderRadius: '50%',
    },
    '&>span': {
      ...theme.typography.button,
      textTransform: 'none',
    },
    '&>span>svg': {
      color: theme.palette.common.white,
      fontSize: 'inherit!important',
    },
    '&.Mui-disabled>span>svg': {
      color: theme.palette.action.disabledInverse,
    },
  });
}

function avatarStyle(theme?: CustomTheme) {
  return ({
    '&': {
      border: 0,
      padding: 7,
      height: '64px',
      width: '64px',
      alignSelf: 'center',
    },
    '&:hover': {
      backgroundColor: theme?.palette.action.hoverInverse,
    },
    '&>span>svg': {
      color: theme?.palette.common.white,
      fontSize: 'inherit!important',
    },
    '&.Mui-disabled>span>svg': {
      color: theme?.palette.action.disabledInverse,
    },
  });
}

function commonLinkAndNavStyle(theme: CustomTheme) {
  return ({
    '&': {
      textDecoration: 'none',
      display: 'block',
    },
    '&>div:hover': {
      backgroundColor: theme.palette.action.hoverInverse,
    },
    '&>span>svg': {
      color: theme.palette.common.white,
    },
    '&.Mui-disabled>span>svg': {
      color: theme.palette.action.disabledInverse,
    },
  });
}

const StyledToggleButton = styled(ToggleButton)(({ theme }: { theme: CustomTheme }) => commonIconAndToggleStyle(theme));
const StyledIconButton = styled(IconButton)(({ theme }: { theme: CustomTheme }) => commonIconAndToggleStyle(theme));
const StyledAvatarButton = styled(ButtonBase)(({ theme }: { theme?: CustomTheme }) => avatarStyle(theme));
const StyledNavButton = styled(ButtonBase)(({ theme }: { theme: CustomTheme }) => commonLinkAndNavStyle(theme));
const StyledLink = styled(Link)(({ theme }: { theme: CustomTheme }) => commonLinkAndNavStyle(theme));

export {
  StyledToggleButton, StyledAvatarButton, StyledIconButton, StyledLink, StyledNavButton,
};
