/* eslint-disable no-bitwise */
// Them
import React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Us
import { FormDefTypes } from '@models/CreateFormDef';
import { useStepperContext } from './StepData';

interface IFormDefOptions {
  name: string;
  detail: string;
  type: FormDefTypes;
}

const formDefOptions: IFormDefOptions[] = [
  {
    name: 'Email Template',
    detail: 'Only generates plain text of an email message and cannot use any data input controls. Cannot combine with other options.',
    type: FormDefTypes.EmailTemplate,
  },
  {
    name: 'Form Custom Task',
    detail: 'Provides users that are building Forms the ability to execute custom business logic when the end-user is filling out the Form.',
    type: FormDefTypes.FormCustomTask,
  },
  {
    name: 'Rule Custom Task',
    detail: 'Provides users that are building Business Rules the ability to use custom business logic in the Process Timeline definition.',
    type: FormDefTypes.RuleCustomTask,
  },
  {
    name: 'Timeline Custom Task',
    detail: 'Provides users that are building Process Timelines the ability to use custom business logic in the Process Timeline definition.',
    type: FormDefTypes.TimelineCustomTask,
  },
  {
    name: 'Workflow Custom Task (legacy)',
    detail: 'Provides a custom task that can be used as a Workflow Step.',
    type: FormDefTypes.WorkflowCustomTask,
  },
];

export default function FormDefDetails() {
  const { formDefOptions: { selected, setSelected } } = useStepperContext();

  const handleSelect = (option: IFormDefOptions) => {
    if ((selected & option.type) === option.type) {
      setSelected(selected & ~option.type);
    } else if (option.name === 'Email Template') {
      setSelected(option.type);
    } else {
      setSelected(selected | option.type);
    }
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="options-content"
          id="options-content"
        >
          <Typography>Additional Form Definition Options</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Choose one or more.
          </Typography>
          <List sx={{ maxHeight: '15rem', overflow: 'auto' }} component="div">
            {formDefOptions.map((option) => (
              <ListItemButton alignItems="flex-start" key={option.name} selected={(selected & option.type) === option.type} onClick={() => handleSelect(option)}>
                <ListItemText
                  primary={option.name}
                  secondary={option.detail}
                />
              </ListItemButton>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
