// Them
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

// Us
import ButtonBase from '@mui/material/ButtonBase';
import Icon from '@components/layout/Icon';
import {
  IProps, useStepperContext, objects, useValidator,
} from './StepData';

export default function Create(props: IProps) {
  const { open } = props;
  const {
    loading, success, error, selectedObject, selectedFolder, objectName,
  } = useStepperContext();
  const obj = objects.find((o) => o.name === selectedObject);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useValidator(props, (enable) => true, []);

  const bgPicker = (successColor: string, errorColor: string, loadingColor: string) => {
    if (loading) {
      return loadingColor;
    } if (success) {
      return successColor;
    }
    return errorColor;
  };

  return (
    <Box sx={{ typography: 'body1', display: open ? '' : 'none' }}>
      <Typography sx={{ mt: '1rem', mb: '2rem' }}>
        {success
          ? `${obj?.name} created successfully. Click Close to view and modify it.`
          : `Everything's ready to go. Confirm the details below and click Create to create your ${obj?.name}.`}
      </Typography>
      <Box sx={{ width: 0.6, m: '5rem auto 2rem auto' }}>
        <Typography variant="subtitle1" sx={{ mb: '1rem' }} data-cy="Create_ObjectName">
          {`${obj?.name} Name: ${objectName}`}
        </Typography>
        <Box
          sx={{ display: 'grid', gridTemplateColumns: '1fr 8fr' }}
        >
          <ButtonBase sx={{
            objectFit: 'cover',
            borderRadius: '10px',
            overflow: 'hidden',
            width: '65px',
            height: '65px',
            cursor: 'pointer',
            alignSelf: 'center',
            backgroundColor: (theme) => theme.components.MuiAppBar.styleOverrides.root.backgroundColor,
          }}
          >
            {obj && (<Icon icon={obj!.icon} color="white" variant="lg" />)}
          </ButtonBase>
          <Box sx={{ mb: '2rem' }}>
            <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
              {obj?.name ?? ''}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: 14 }}>
              {obj?.description ?? ''}
            </Typography>
          </Box>
        </Box>
        <Typography variant="subtitle2" sx={{ mb: '2rem' }} data-cy="Create_DestinationFolder">
          Destination Folder:&nbsp;
          {selectedFolder?.[1] ?? ''}
        </Typography>
      </Box>
      {loading !== undefined && (
        <Box sx={{
          display: 'grid', placeItems: 'center',
        }}
        >
          <Box sx={{
            m: 1, position: 'relative', display: 'grid', placeItem: 'center',
          }}
          >
            <Box sx={{
              color: 'white', width: 56, height: 56, borderRadius: '50%', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', backgroundColor: (theme) => bgPicker(green[500], red[500], theme.palette.primary.main),
            }}
            >
              {loading && <SaveIcon />}
              {!loading && (success ? <CheckIcon /> : <ErrorOutline />)}
            </Box>
            {loading && (
              <CircularProgress
                size={68}
                sx={{
                  position: 'absolute',
                  top: -6,
                  left: -6,
                  zIndex: 1,
                }}
              />
            )}
          </Box>
          <Typography variant="subtitle1" sx={{ mt: '.5rem' }}>
            {loading && 'Creating...'}
            {!loading && (success ? 'Created' : 'Failed')}
          </Typography>
          {error && (
            <Typography sx={{ mt: '1rem', color: (theme) => theme.palette.error.main }}>
              {error}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}
