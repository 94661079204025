// Them
import { useEffect, DependencyList, useState } from 'react';

// Us
import { ErrorResult } from '@components/data/rest';

export interface IMap {
  [key: number]: {
    validate: (enable?: boolean) => boolean;
  }
}

export interface IProps {
  open: boolean;
  validators: IMap;
  stepNum: number;
}

export interface IStepperContext {
  // Settings
  loading?: boolean;
  success: boolean;
  error?: string;
  activeStep: number;
  // Data
  // ... leave to "extenders"
}

export function getFriendlyError(apiError: ErrorResult): string {
  const { httpStatusCode, errorMessage } = apiError;
  switch (httpStatusCode) {
    case 401:
      return 'You are not authorized to perform this action.';
    default:
      if (!httpStatusCode) {
        return 'The server is down or you are disconnected from the network.';
      }
      return errorMessage;
  }
}

export function useValidator({ activeStep }: IStepperContext, { validators, stepNum }: IProps, validator: (enable?: boolean) => boolean, deps?: DependencyList) {
  const [enableValidation, setEnableValidation] = useState(false);
  useEffect(() => {
    const validate = (enable?: boolean) => {
      if (activeStep >= stepNum && enable) {
        setEnableValidation(true);
      } else {
        // eslint-disable-next-line no-param-reassign
        enable = enableValidation;
      }
      if (activeStep >= stepNum && enable) {
        return validator(enable);
      }
      return true;
    };
    validate();
    if (validators) {
      // eslint-disable-next-line no-param-reassign
      validators[stepNum] = {
        validate,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepNum, activeStep, enableValidation, validator, validators, ...(deps || [])]);
}
