/* eslint-disable @typescript-eslint/no-unused-vars */
// Them
import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

// Us
import ImportResult from '@models/ImportResult';
import { getEnumResource } from '@components/utilities/Resources';

const columns: GridColDef[] = [
  {
    field: 'type',
    headerName: 'Type',
    width: 75,
    valueGetter: (params: GridValueGetterParams) => getEnumResource('ImportMsgType', params.row.type.toString()),
  },
  {
    field: 'text',
    headerName: 'Message',
    width: 500,
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {params.row.text}
      </div>
    ),
  },
  {
    field: 'parentName',
    headerName: 'Parent Name',
    width: 150,
  },
  {
    field: 'parentType',
    headerName: 'Parent Type',
    // description: 'This column has a value getter and is not sortable.',
    // sortable: false,
    width: 110,
    valueGetter: (params: GridValueGetterParams) => getEnumResource('OBJTYPE', params.row.parentType.toString()),
  },
  {
    field: 'objName',
    headerName: 'Object Name',
    width: 150,
  },
  {
    field: 'objType',
    headerName: 'Object Type',
    width: 110,
    // type: 'number',
    valueGetter: (params: GridValueGetterParams) => getEnumResource('OBJTYPE', params.row.objType.toString()),
  },
];

interface IProps {
  data: ImportResult[];
}

export default function DataGridDemo(props: IProps) {
  const { data } = props;
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        sx={{
          '& .MuiDataGrid-cell': {
            fontSize: '12px',
          },
        }}
        pageSizeOptions={[100]}
        density="compact"
        disableRowSelectionOnClick
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd')}
      />
    </Box>
  );
}
