export function getCookie(name: string): string | undefined {
  const cookies = document.cookie.split(';').map((cookie) => {
    const [key, value] = cookie.split('=');
    return [key.trim(), value];
  });

  return cookies.find((c) => c[0] === name)?.[1];
}

export function validateHttpOnlyCookie(name: string): string {
  // Attempts to create a dummy cookie for session user. If it fails, then the cookie already exists, as an HttpOnly cookie.
  const date = new Date();
  // Setting expiration date to one second, so the dummy cookie will expire and be removed right after this.
  date.setTime(date.getTime() + (1000));
  const newCookie = `;${name}=new_value; path=/; expires=${date.toUTCString()}`;
  document.cookie += newCookie;
  return document.cookie.indexOf(`${name}=`) === -1 ? name : '';
}
