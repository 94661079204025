// Them
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Clear from '@mui/icons-material/Clear';

// Us
import { IProps, useStepperContext, useValidator } from './StepData';
import FolderTree from '../FolderTree';

export default function PickFolder(props: IProps) {
  const { open } = props;
  const [errorSelectedFolder, setErrorSelectedFolder] = useState<string>('');
  const [errorAppName, setErrorAppName] = useState<string>('');
  const {
    applicationName,
    setApplicationName,
    selectedFolder,
    setSelectedFolder,
    newFolder,
    setNewFolder,
  } = useStepperContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useValidator(props, (enable) => {
    let error = false;
    if (!selectedFolder[0]) {
      setErrorSelectedFolder('Please select a folder');
      error = true;
    } else {
      setErrorSelectedFolder('');
    }
    if (!applicationName) {
      setErrorAppName('Please provide an application name');
      error = true;
    } else {
      setErrorAppName('');
    }
    return !error;
  }, [applicationName, selectedFolder, newFolder]);

  return (
    <Box sx={{ typography: 'body1', display: open ? '' : 'none' }}>
      <Grid container columns={{ md: 2 }} sx={{ marginTop: '0rem' }}>
        <Grid xs={1} sx={{ padding: '2rem' }}>
          <Box>
            <Typography sx={{ marginBottom: '2rem', marginTop: '2rem' }}>
              Now give your application a name and identify a folder to create it in. You can use an existing folder or create a new one.
            </Typography>
            <TextField
              aria-label="application name"
              label="Application Name"
              value={applicationName}
              helperText={errorAppName}
              error={!!errorAppName}
              onChange={(e) => setApplicationName(e.currentTarget.value)}
              variant="standard"
              sx={{ width: '80%', mb: '2rem' }}
            />
            <TextField
              aria-label="selected folder"
              label="Selected Folder"
              helperText={errorSelectedFolder || 'Select a folder in the tree'}
              error={!!errorSelectedFolder}
              InputProps={{
                readOnly: true,
              }}
              value={selectedFolder[1]}
              variant="standard"
              sx={{ width: '80%', mb: '1rem' }}
            />
            <FormControl sx={{ width: '80%' }} variant="standard">
              <InputLabel htmlFor="subfolder">New Sub-Folder (optional)</InputLabel>
              <Input
                aria-label="new optional sub-folder"
                id="subfolder"
                value={newFolder}
                onChange={(e) => setNewFolder(e.currentTarget.value)}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear new sub-folder"
                      onClick={() => setNewFolder('')}
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid
          xs={1}
          sx={{
            height: '55vh', overflow: 'auto',
          }}
        >
          <FolderTree onSelectFolder={setSelectedFolder} selected={selectedFolder[0]} />
        </Grid>
      </Grid>
    </Box>
  );
}
