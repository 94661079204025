/* eslint-disable no-bitwise */
/* eslint-disable import/prefer-default-export */
function parseEnumValueImpl<T>(value: string | number, enumType: T): number {
  const result = Number.isNaN(parseInt(value.toString(), 10)) ? enumType[value.toString() as keyof T] : parseInt(value.toString(), 10);
  return result as number;
}

export function parseEnumValue<T>(value: string | number, enumType: T): number {
  // Handle bit-vector (AKA flags) values (e.g. "1, 2, 4, 8").
  const splits = value.toString().split(',').map((v) => v.trim());
  return splits
    .map((item) => parseEnumValueImpl(item, enumType) as number)
    .reduce((acc, cur) => acc | cur, 0) as number;
}
