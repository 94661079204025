// Them
import React, { useRef, useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';

interface OwnProps {
  tooltip: string | React.ReactFragment;
  sx?: SxProps<Theme>;
}

// See https://stackoverflow.com/a/59364492/1455091.
export default function OverflowTip(props: React.PropsWithChildren<OwnProps>) {
  const { tooltip, sx, children } = props;
  const textElementRef = useRef<HTMLInputElement | null>(null);
  const [disableHoverListener, setDisableHoverListener] = useState(false);

  const compareSize = () => {
    const disable = !!textElementRef.current && textElementRef.current.scrollWidth <= textElementRef.current.clientWidth;
    setDisableHoverListener(disable);
  };

  // Note, the way this effect is created (no second parameter, aka dependencies), means it will run after every render.
  useEffect(() => {
    compareSize();
    // Our items don't resize (for now). So let's avoid this overhead.
    // window.addEventListener('resize', compareSize);

    // return () => { window.removeEventListener('resize', compareSize); };
  });

  return (
    <Tooltip
      title={tooltip}
      disableHoverListener={disableHoverListener}
    >
      <Box
        ref={textElementRef}
        sx={{
          ...sx,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </Box>
    </Tooltip>
  );
}
