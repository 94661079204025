// Them
import React from 'react';
import { useLocation } from 'react-router';

// Us
import { useSmartReduxUpdates } from '@components/data/reduxMonitor';
import { useClientHubMonitor } from '@components/data/clientHubMonitor';

interface IProps {
  ignoredPaths: string[];
}

/**
 * This component is used to monitor the global state of the app. It should be mounted once and only once.
 * @param ignoredPaths - An array of paths that should not be monitored.
 */
export default function GlobalStateMonitor({ ignoredPaths }: IProps) {
  const loc = useLocation().pathname.toLowerCase();
  const isEmbeddedPage = ignoredPaths.includes(loc);

  // These hooks count on this component being mounted once for the life of the app.
  useClientHubMonitor(!isEmbeddedPage);
  useSmartReduxUpdates(!isEmbeddedPage);

  return (<></>);
}
