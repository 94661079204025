// Them
import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// Us
import { useStepperContext } from './StepData';

export default function DocDetails() {
  const { fileOptions: { selectedFile, setSelectedFile } } = useStepperContext();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  return (
    <>
      {selectedFile && (
        <Typography sx={{ mb: '1rem' }}>
          {selectedFile.name}
        </Typography>
      )}
      <Button variant="contained" component="label">
        Upload File
        <input type="file" hidden onChange={handleFileChange} />
      </Button>
    </>
  );
}
