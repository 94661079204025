import { CreateObject } from './CreateObject';

export enum ReportTypes {
  Multipage = 0,
  Dashboard = 1, // This should be the default but it's value is set to match legacy on server.
  Portlet = 2,
}

export default interface CreateReport extends CreateObject {
  reportTypes: ReportTypes;
}
