/* eslint-disable no-bitwise */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import ToggleButton from '@mui/material/ToggleButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MenuItem from '@mui/material/MenuItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

// Us
import Workspace from '@models/Workspace';
import { setActiveWorkspaceAsync } from '@store/workspacesSlice';
import { CustomTheme } from '@components/layout/Theme';
import { navContent } from '@components/utilities/Navigation';
import Icon, { showIcon } from './Icon';

const StyledToggleButton = styled(ToggleButton)(({ theme }: { theme: CustomTheme }) => ({
  '&': {
    ...theme.typography.button,
    textTransform: 'none',
    fontWeight: 'bold',
    height: '30px',
    alignSelf: 'center',
    color: theme.palette.common.white,
    marginRight: theme.spacing(0.5),
    borderWidth: 0,
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: theme.palette.action.hoverInverse,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hoverInverse,
  },
}));

interface OwnProps {
  workspaces?: Workspace[];
}

export default function Workspaces(props: React.PropsWithChildren<OwnProps>) {
  const { workspaces } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(!anchorEl ? event.currentTarget : null);
  };

  const handleSwitchWorkspace = async (workspace: Workspace) => {
    setActiveWorkspaceAsync(dispatch, workspace.id);
    navContent(history, workspace.url);
    setAnchorEl(null);
  };

  return (
    <>
      <StyledToggleButton value="depressed" selected={!!anchorEl} onClick={handleClick}>
        Workspaces
        {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </StyledToggleButton>
      <Menu
        id="WorkspacesMenu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {workspaces && workspaces.length && workspaces
          .slice()
          .sort((lhs, rhs) => lhs.order - rhs.order)
          .map(
            (child) => (
              <MenuItem key={child.id} onClick={() => handleSwitchWorkspace(child)} sx={{ paddingLeft: '4px' }}>
                <ListItemButton>
                  {showIcon(child.displayOptions) && <Icon icon={child.icon} />}
                  <ListItemText primary={child.name} sx={{ paddingLeft: showIcon(child.displayOptions) ? 0 : '21px' }} />
                </ListItemButton>
              </MenuItem>
            ),
          )}
      </Menu>
    </>
  );
}
