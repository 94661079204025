// Them
import { createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Us

const stagingTheme = createTheme();
const ourFonts = '"Open Sans", "Segoe UI", "Roboto", "Helvetica", "Arial", "sans-serif"';
const chromeBackground = '#082F4D';
const theme = createTheme(stagingTheme, {
  palette: {
    action: {
      activeInverse: '#000',
      hover: 'rgba(237, 244, 254, 1)',
      hoverInverse: 'rgba(255, 255, 255, 0.3)',
      hoverOpacityInverse: 0.3,
      selectedInverse: 'rgba(255, 255, 255, 0.16)',
      selectedOpacityInverse: 0.16,
      disabledInverse: 'rgba(255, 255, 255, 0.3)',
      disabledOpacityInverse: 0.38,
      disabledBackgroundInverse: 'rgba(255, 255, 255, 0.12)',
      focusInverse: 'rgba(255, 255, 255, 0.12)',
      focusOpacityInverse: 0.12,
      activatedOpacityInverse: 0.24,
    },
  },
  // Any common theme-related but unique to BP Logix items go here. First make sure there
  // isn't a relevant item elsewhere in mui's theme, though. Note, items below in components
  // cannot be referenced in our components. They are meant to be used by the related
  // mui components directly.
  bpcustom: {
    chromeBackground,
  },
  typography: {
    fontFamily: ourFonts,
    h1: {
      fontFamily: ourFonts,
    },
    h2: {
      fontFamily: ourFonts,
    },
    h3: {
      fontFamily: ourFonts,
    },
    h4: {
      fontFamily: ourFonts,
    },
    h5: {
      fontFamily: ourFonts,
    },
    h6: {
      fontFamily: ourFonts,
    },
    subtitle1: {
      fontFamily: ourFonts,
    },
    subtitle2: {
      fontFamily: ourFonts,
    },
    body1: {
      fontFamily: ourFonts,
      fontSize: '0.875rem',
    },
    body2: {
      fontFamily: ourFonts,
    },
    button: {
      fontFamily: ourFonts,
    },
    caption: {
      fontFamily: ourFonts,
    },
    overline: {
      fontFamily: ourFonts,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 96,
      [stagingTheme.breakpoints.down('md')]: {
        minHeight: 60,
      },
      '@media (min-width: 0px) and (orientation: landscape)': {
        minHeight: 96,
      },
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: chromeBackground,
          zIndex: 1201,
          transition: stagingTheme.transitions.create(['width', 'margin'], {
            easing: stagingTheme.transitions.easing.sharp,
            duration: stagingTheme.transitions.duration.leavingScreen,
          }),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#116EB6',
          color: stagingTheme.palette.common.white,
          fontSize: stagingTheme.typography.body2.fontSize,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          maxHeight: '2.75rem',
          height: '2.75rem',
          fontSize: '14px',
          '& *': {
            fontSize: '14px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
  },
});

export default theme;
export type CustomTheme = typeof theme;

export function useResponsiveToolbarHeight(breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md'): number {
  const mediaQuery = theme.breakpoints.down(breakpoint);
  const isMobile = useMediaQuery(breakpoint);
  const value = isMobile ? (theme.mixins.toolbar as any)[mediaQuery].minHeight : theme.mixins.toolbar.minHeight;

  return typeof value === 'number' ? value as number : parseInt(value as string, 10);
}
