/* eslint-disable no-bitwise */
import React from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { CustomTheme } from '@components/layout/Theme';

// Us
import { setActiveWorkspaceAsync, useWorkspaces } from '@store/workspacesSlice';

const StyledClose = styled(Close)(({ theme }: { theme: CustomTheme }) => ({
  '&': {
    ...theme.typography.button,
    display: 'flex',
    padding: '2px',
    borderRadius: '10px',
    color: theme.palette.common.black,
    transition: '0.2s',
  },
  '&:hover': {
    color: theme.palette.common.white,
    backgroundColor: '#a1a1a1',
  },
}));

interface OwnProps {
  activeCaseId?: string;
  onClose?(event: object): any;
}

export default function CaseCloser(props: React.PropsWithChildren<OwnProps>) {
  const { activeCaseId, onClose } = props;
  const dispatch = useDispatch();

  const workspaces = useWorkspaces();
  const first = workspaces.length > 0 ? workspaces[0] : undefined;

  const safeClose = () => {
    if (onClose) { onClose((<></>)); }
  };

  const leaveCase = () => {
    setActiveWorkspaceAsync(dispatch, first?.id ?? '');
    safeClose();
  };

  return (
    <Link to={`/if/workspace.aspx?CLOSECASE=${activeCaseId}`} onClick={leaveCase} style={{ textDecoration: 'none', color: 'inherit' }}>
      <StyledClose />
    </Link>
  );
}
