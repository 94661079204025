/* eslint-disable import/prefer-default-export */
// Them
import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import $ from 'jquery';
import 'signalr';

// Us
import { SignalR } from '@components/data/signalr-bp';
import {
  logger, gnFgGyBg, whFgGnBg, whFgRdBg,
} from '@components/utilities/logger';
import { networkRestoredChannelName } from '@components/data/NetworkMonitor';
import { basePath } from '@components/utilities/Paths';

const isProduction = process.env.NODE_ENV === 'production';

// This is needed because SignalR's connection.id is not always available for all states
// the object can be in.
function addUniqueId(obj: any) {
  // eslint-disable-next-line no-param-reassign, no-plusplus
  obj.uniqueId = (Math.random() + 1).toString(36).substring(5);
}

function getConnInfo(conn: SignalR.Hub.Connection | null | undefined): string {
  return conn ? `${conn.id}|${(conn as any).uniqueId}:${conn.state})}` : 'n/a';
}

function registerLiveStats(conn: SignalR.Hub.Connection, clHub: SignalR.Hub.Proxy, clear: boolean, interval: number, nextOp?: () => void): number {
  const liveStatsHeartbeat = window.setInterval(() => {
    if (conn.state === $.signalR.connectionState.connected) {
      try {
        logger.info(...gnFgGyBg(`Registering live stats listener (${getConnInfo(conn)}`));
        clHub.invoke('RegisterLiveStatsListener');
        if (clear) {
          window.clearInterval(liveStatsHeartbeat);
        }
        nextOp?.();
        // eslint-disable-next-line no-empty
      } catch { }
    }
  }, interval);
  return liveStatsHeartbeat;
}

export function useClientHubLiveStats(reportLiveStatsRef: React.MutableRefObject<((msg: string) => void) | undefined>) {
  const connectionRef = useRef<SignalR.Hub.Connection | null>(null);
  const [connection, setConnection] = useState<SignalR.Hub.Connection | null>(null);
  const [theHub, setTheHub] = useState<SignalR.Hub.Proxy | null>(null);
  const [refreshSignalR, setRefreshSignalR] = useState<boolean>(false);
  const [checkConnection, setCheckConnection] = useState(false);

  useEffect(() => {
    connectionRef.current = connection;
  }, [connection]);

  useEffect(() => {
    const channel = new BroadcastChannel(networkRestoredChannelName);
    channel.onmessage = (event: MessageEvent<string>) => setRefreshSignalR(true);

    return () => {
      channel.close();
    };
  }, []);

  useEffect(() => {
    let conn = connectionRef.current;
    if (checkConnection && conn && conn.state === $.signalR.connectionState.disconnected) {
      logger.info(...gnFgGyBg(`SignalR disconnect detected (${getConnInfo(conn)})})`));
      setCheckConnection(false);
      // Wait long enough that we avoid reloads from causing a false disconnect when using the React dev server for development.
      setTimeout(() => {
        conn = connectionRef.current;
        if (conn && conn.state === $.signalR.connectionState.disconnected) {
          logger.info(...whFgRdBg(`Connection lost, waiting for network monitor broadcast (${getConnInfo(conn)})`));
        }
      }, 5000);
    } else {
      logger.info(...whFgGnBg(`Connection check ok (${getConnInfo(conn)})`));
    }
  }, [connectionRef, checkConnection]);

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let liveStatsHeartbeat = 0;
    if (refreshSignalR || !connection) {
      // Only set back to false if true, otherwise we have a race condition with state updates.
      if (refreshSignalR) {
        setRefreshSignalR(false);
      }
      if (connection && connection.state !== $.signalR.connectionState.disconnected) {
        try {
          connection.stop();
          // eslint-disable-next-line no-empty
        } catch { }
      }
      const conn = $.hubConnection(basePath.replace(/\/$/, ''), { logging: !isProduction }) as SignalR.Hub.Connection;
      addUniqueId(conn);
      conn
        .error((error) => {
          logger.error(`SignalR error: ${JSON.stringify(error, undefined, 2)}`);
        })
        .stateChanged((change) => {
          // From https://github.com/SignalR/SignalR/blob/f3600c71f83d8312ad61bced0ca547795734d51e/src/Microsoft.AspNet.SignalR.JS/jquery.signalR.core.js#L241
          // signalR.connectionState = {
          //   connecting: 0,
          //   connected: 1,
          //   reconnecting: 2,
          //   disconnected: 4
          // };
          logger.debug(`SignalR state changed: (${getConnInfo(conn)}) ${JSON.stringify(change, undefined, 2)}`);
          if (change.newState === $.signalR.connectionState.disconnected) {
            logger.debug('SignalR disconnected');
            setCheckConnection(true);
          }
        })
        .disconnected(() => {
          logger.info(...whFgRdBg(`Disconnected from client hub, connection ID=${conn.id}.`));
        });

      const clHub = conn.createHubProxy('clientHub');
      logger.info(...gnFgGyBg('Wiring ClientHub: connect callbacks'));
      clHub.on('ReceiveLiveStatsMessage', (msg: string) => {
        logger.debug(...whFgGnBg('ClientHub: ReceiveLiveStatsMessage received'));
        reportLiveStatsRef.current?.(msg);
      });

      const connect = async () => {
        try {
          await conn.start();
        } catch (error) {
          logger.error(`Could not start client hub: ${error}`);
        }
        logger.info(`Starting client hub, connection ID=${getConnInfo(conn)}`);
        liveStatsHeartbeat = registerLiveStats(conn, clHub, true, 250, () => {
          liveStatsHeartbeat = registerLiveStats(conn, clHub, false, 50000);
        });
      };

      if (conn.state === $.signalR.connectionState.disconnected) {
        connect();
      }
      setTheHub(clHub);
      setConnection(conn);
    }

    // Clean up on unmount
    return () => {
      if (liveStatsHeartbeat) clearInterval(liveStatsHeartbeat);
      if (connection?.state === $.signalR.connectionState.connected) {
        logger.info(...gnFgGyBg('Unregistering live stats listener'));
        theHub?.invoke('UnregisterLiveStatsListener');
        logger.info(...gnFgGyBg('Stopping hub connection'));
        connection?.stop();
        setTheHub(null);
        setConnection(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, refreshSignalR]);
}
