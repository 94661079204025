// Them
import React from 'react';

// Us
import { reactPath } from '@components/utilities/Paths';

const NotFound = () => (
  <header className="App-header">
    <p style={{ textAlign: 'center' }}>
      404 Page not found
      <img src={`${reactPath}/bp-logix-logo.svg`} alt="" />
    </p>
  </header>
);

export default NotFound;
