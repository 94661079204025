// Them
import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// Us

interface CustomButton {
  label: string;
  onClick: () => void;
  default?: boolean;
}

interface IProps {
  heading: string;
  body: string | string[];
  helpLink?: string;
  onClose?: () => void;
  customButtons?: CustomButton[];
  open: boolean;
}

export default function BasicModal(props: IProps) {
  const {
    heading, body, helpLink, onClose, open, customButtons,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle sx={{ paddingBottom: 0 }}>
        {heading}
      </DialogTitle>
      <DialogContent>
        {Array.isArray(body) ? body.map((paragraph) => (
          <Typography id="modal-modal-description" sx={{ mt: 2 }} key={paragraph}>
            {paragraph}
          </Typography>
        )) : (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {body}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ marginTop: 'auto', alignSelf: 'flex-end' }}>
        {customButtons && customButtons.map((button) => (
          <Button size="small" onClick={button.onClick} variant={button.default ? 'contained' : 'text'} key={button.label}>
            {button.label}
          </Button>
        ))}
        {onClose && (
          <Button size="small" onClick={onClose}>
            Close
          </Button>
        )}
        {helpLink && (
          <Button size="small" href={`https://doc.bplogix.com/content/redirect_help.aspx?control=${helpLink}`} target="_blank" rel="noopener noreferrer">
            <OpenInNewIcon fontSize="small" sx={{ mr: '.1rem' }} />
            Learn More
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
