import { store } from '@store/store';
import { selectResources } from '@store/resourcesSlice';

// eslint-disable-next-line import/prefer-default-export
export function getEnumResource(enumName: string, enumValue: string): string {
  if (!enumValue) return '';
  const resources = selectResources(store.getState());
  const enumResource = resources[enumName.toLowerCase()] ? resources[enumName.toLowerCase()].find((resource) => resource.name === enumValue || resource.value === parseInt(enumValue, 10)) : null;
  return enumResource ? enumResource.displayString : enumValue;
}
