import { CreateObject } from './CreateObject';

export enum FormDefTypes {
  None = 0,
  FormCustomTask = 1,
  WorkflowCustomTask = 2,
  TimelineCustomTask = 4,
  RuleCustomTask = 8,
  EmailTemplate = 16,
}

export default interface CreateFormDef extends CreateObject {
  formDefTypes: FormDefTypes;
}
