// Them
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';

// Us
import CustomIcon from '@components/layout/Icon';
import {
  objects, IProps, useStepperContext, useValidator,
} from './StepData';
import FormDefDetails from './FormDefDetails';
import ReportDetails from './ReportDetails';
import FileDetails from './FileDetails';

export default function PickObject(props: IProps) {
  const { open } = props;
  const [errorNoObject, setErrorNoObject] = useState<boolean>(false);
  const {
    selectedObject, setSelectedObject, fileOptions: { selectedFile },
  } = useStepperContext();
  const [errorNoFile, setErrorNoFile] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useValidator(props, (enable) => {
    const error = false;
    if (!selectedObject) {
      setErrorNoObject(true);
      return false;
      // eslint-disable-next-line no-else-return
    } else {
      setErrorNoObject(false);
    }
    if (selectedObject === 'Document / File' && !selectedFile) {
      setErrorNoFile(true);
      return false;
      // eslint-disable-next-line no-else-return
    } else {
      setErrorNoFile(false);
    }
    return !error;
  }, [selectedObject, selectedFile]);

  const handleSelect = (name: string) => {
    setErrorNoObject(false);
    setSelectedObject(name);
  };

  const addDivider = (key: string, index: number) => (index !== 0 ? (<Divider key={`div:${key}`} component="li" />) : (<></>));

  return (
    <Box sx={{ typography: 'body1', display: open ? '' : 'none' }}>
      <Grid container columns={{ xl: 2 }} sx={{ mt: '0rem' }}>
        <Grid item xs={1} sx={{ padding: '2rem' }}>
          <Typography sx={{ mt: '1rem', mb: '2rem' }}>
            Choose an object type from the list to get started.
          </Typography>
          {(errorNoObject || errorNoFile) && (
            <Typography sx={{ mt: '5rem', mb: '2rem', color: (theme) => theme.palette.error.main }}>
              {errorNoObject && 'Please choose an object type to proceed.'}
              {errorNoObject && errorNoFile && <br />}
              {errorNoFile && 'Please choose a file to proceed.'}
            </Typography>
          )}
          {selectedObject === 'Form Definition' && (<FormDefDetails />)}
          {selectedObject === 'Report' && (<ReportDetails />)}
          {selectedObject === 'Document / File' && (<FileDetails />)}
        </Grid>
        <Grid item xs={1} sx={{ padding: '0rem' }} data-cy="PickObject_Objects">
          <Grid container columns={{ xl: 1 }} sx={{ mt: '0rem' }}>
            <Box sx={{ height: '55vh', overflowY: 'auto' }}>
              <List>
                {objects?.map((obj, index) => (
                  <div key={obj.name}>
                    {addDivider(obj.name, index)}
                    <ListItemButton alignItems="flex-start" key={obj.name} onClick={() => handleSelect(obj.name)} selected={selectedObject === obj.name} sx={{ px: 0 }}>
                      <Box
                        sx={{ display: 'grid', gridTemplateColumns: '1fr 12fr' }}
                      >
                        <ButtonBase sx={{
                          objectFit: 'cover',
                          borderRadius: '10px',
                          overflow: 'hidden',
                          width: '65px',
                          height: '65px',
                          cursor: 'pointer',
                          alignSelf: 'center',
                          backgroundColor: (theme) => theme.bpcustom.chromeBackground,
                        }}
                        >
                          <CustomIcon icon={obj.icon} color="white" variant="lg" />
                        </ButtonBase>
                        <ListItemText
                          sx={{ ml: '.33rem', '& .MuiListItemText-primary': { fontWeight: 'bold' } }}
                          primary={obj.name}
                          secondary={obj.description}
                        />
                      </Box>
                    </ListItemButton>
                  </div>
                ))}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
