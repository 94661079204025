/* eslint-disable import/prefer-default-export */

/**
 * A simple hash function for strings. Useful for React keys.
 */
export function hashString(str: string): string {
  let hash = 5381;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = (hash * 33) ^ str.charCodeAt(i);
  }
  // eslint-disable-next-line no-bitwise
  return (hash >>> 0).toString(); // Ensure non-negative integer
}
