// Them
import React, { useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

// Us
import ImportResult from '@models/ImportResult';
import { postAsync } from '@components/data/rest';
import { navContent } from '@components/utilities/Navigation';
import { schemeServerBasePath } from '@components/utilities/Paths';
import PickFolder from './PickFolder';
import Review from './Review';
import {
  IMap, StepperContext,
} from './StepData';
import { getFriendlyError } from '../StepperCommon';

interface IStep {
  name: string;
  component: (stepNum: number, open: boolean, dataMap: IMap) => React.ReactNode;
}

const steps: IStep[] = [
  {
    name: 'Select File and Destination',
    component: (stepNum, open, validators) => <PickFolder key="folder" stepNum={stepNum} open={open} validators={validators} />,
  },
  {
    name: 'Review',
    component: (stepNum, open, validators) => <Review key="review" stepNum={stepNum} open={open} validators={validators} />,
  },
];

interface IProps {
  open: boolean;
  onClose: () => void;
}

export default function ImportPdzDialog(props: IProps) {
  const { onClose, open } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean | undefined>(undefined);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState('');
  const [resultUrl, setResultUrl] = React.useState('');
  const [selectedFolder, setSelectedFolder] = useState<string[]>(['', '']);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [importResults, setImportResults] = useState<ImportResult[]>([]);
  const dataMap = useRef<IMap>({});
  const history = useHistory();

  const handleNext = () => {
    const validator = dataMap.current[activeStep];
    if (validator) {
      const validated = validator.validate(true);
      if (validated) {
        setActiveStep(activeStep + 1);
        if (activeStep === 0) {
          postData();
        }
      }
    }
  };

  const postData = useCallback(async () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      setError('');
      const formData = new FormData();
      formData.append('file', selectedFile!, selectedFile!.name);
      await postAsync({
        route: `Objects/import/${selectedFolder[0]}`,
        data: formData,
      }, async (response) => {
        setSuccess(true);
        setLoading(false);
        setResultUrl(response.response.headers.get('Location')!);
        setImportResults(await response.obj<ImportResult[]>());
      },
      async (apiError) => {
        setSuccess(false);
        setLoading(false);
        setError(getFriendlyError(apiError));
        return true;
      });
    }
  }, [loading, selectedFile, selectedFolder]);

  const handleClose = () => {
    onClose();
    const url = resultUrl.replace(schemeServerBasePath, '');
    navContent(history, url);
  };

  return (
    <StepperContext.Provider value={{
      success,
      loading,
      error,
      activeStep,
      selectedFolder,
      setSelectedFolder,
      selectedFile,
      setSelectedFile,
      importResults,
    }}
    >
      <Dialog
        fullScreen={fullScreen}
        fullWidth={!fullScreen}
        maxWidth={fullScreen ? 'sm' : 'lg'}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Step key={step.name} {...stepProps}>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <StepLabel {...labelProps} id="responsive-dialog-title">{step.name}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <DialogContent sx={{ pb: 0, height: '55vh' }}>
          {steps.map((step, index) => step.component(index, index === activeStep, dataMap.current))}
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep === 0 && (
              <Button onClick={handleNext} data-cy="ImportPDZ_Import">
                Import
              </Button>
            )}
            {(activeStep === steps.length - 1) && !loading && !success && (
              <>
                <Button onClick={onClose} data-cy="ImportPDZ_Cancel">Cancel</Button>
                <Button onClick={postData} data-cy="ImportPDZ_Retry">Retry</Button>
              </>
            )}
            {(activeStep === steps.length - 1) && (loading || success) && (
              <Box sx={{ position: 'relative' }}>
                <Button onClick={handleClose} disabled={loading} data-cy="ImportPDZ_Close">Close</Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </StepperContext.Provider>
  );
}
