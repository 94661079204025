const scripts = document.getElementsByTagName('script');
const index = scripts.length - 1;
const myScript = scripts[index];
const matches = /\/\/[^/]+(.*\/)(rs)(\/)/.exec(myScript.baseURI);

/**
 * Path to website.
 * @example <caption>Example path</caption>
 * /
 * @example <caption>Example path with virtual directory</caption>
 * /foo
 */
export const basePath = (!!matches && matches.length === 4) ? matches[1] : '/';

/**
 * Path to React application.
 * @example <caption>Example path</caption>
 * /rs
 * @example <caption>Example path including leading virtual directory</caption>
 * /foo/rs
 */
export const reactPath = (!!matches && matches.length === 4) ? `${matches[1]}${matches[2]}` : '/rs';

/**
 * Path to React application including trailing slash (TS).
 * @example <caption>Example path</caption>
 * /rs/
 * @example <caption>Example path including leading virtual directory</caption>
 * /foo/rs/
 */
export const reactPathTS = (!!matches && matches.length === 4) ? `${matches[1]}${matches[2]}${matches[3]}` : '/rs/';

const matchesEx = /((?:http|https):\/\/[^/]+)(.*\/)(rs)(\/)/.exec(myScript.baseURI);

/**
 * Scheme and server for React application.
 * @example <caption>Example</caption>
 * http://localhost
 * @example <caption>Example path including leading virtual directory</caption>
 * http://localhost
 */
export const schemeServer = matchesEx![1];

/**
 * Scheme and server for React application with trailing slash.
 * @example <caption>Example</caption>
 * http://localhost/
 * @example <caption>Example including leading virtual directory</caption>
 * http://localhost/
 */
export const schemeServerTS = `${matchesEx![1]}/`;

/**
 * Scheme, server, and base path.
 * @example <caption>Example</caption>
 * http://localhost
 * @example <caption>Example path including leading virtual directory</caption>
 * http://localhost/foo
 */
export const schemeServerBasePath = `${matchesEx![1]}${matchesEx![2].slice(0, -1)}`;

/**
 * Scheme, server, and base path with trailing slash.
 * @example <caption>Example</caption>
 * http://localhost/
 * @example <caption>Example including leading virtual directory</caption>
 * http://localhost/foo/
 */
export const schemeServerBasePathTS = `${matchesEx![1]}${matchesEx![2]}`;

/**
 * Scheme, server, and path to React application.
 * @example <caption>Example</caption>
 * http://localhost/rs/
 * @example <caption>Example including leading virtual directory</caption>
 * http://localhost/foo/rs/
 */
export const reactSchemeServerPath = matchesEx![0];

/**
 * @interface ReactUrlOptions
 * @property {boolean} [makeUnique] - If true, a random query parameter will be added to the URL to make it unique. This can aid in navigation within react-router.
 */
export interface ReactUrlOptions {
  makeUnique?: boolean;
}

/**
 * Obtain a URL within the React application. Unique URLs for Process Director can be generated using an option.
 * @remarks If you're using this method, be sure you ask yourself if react-router-dom
 * {@link https://v5.reactrouter.com/web/api/Hooks/usehistory useHistory()} or
 * {@link https://v5.reactrouter.com/web/api/Link &lt;Link&gt;} aren't better options. You may be causing unnecessary
 * page reloads, including REST calls to reload data.
 * @param path Path within the React application. Note, '/' is the root of the React application but is not the same as the site root.
 * @param options Options for the URL.
 */
export function getReactUrl(path: string, options?: ReactUrlOptions): URL {
  const url = path.startsWith('/') ? new URL(`${reactPath}${path}`, schemeServer) : new URL(`${reactPathTS}${path}`, schemeServer);
  if (options?.makeUnique) {
    url.searchParams.set('ver', (Math.random() + 1).toString(36).substring(5));
  }

  return url;
}
