// Them
import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Button from '@mui/material/Button';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

// Us
// eslint-disable-next-line import/no-cycle
import { RootState, AppDispatch } from '@store/store';
import { useAppSelector } from '@store/hooks';
// eslint-disable-next-line import/no-cycle
import { getAsync, putAsync } from '@components/data/rest';
import ChatMessage, { MessageType } from '@models/ChatMessage';
import { parseEnumValue } from '@components/utilities/enums';

export interface ChatbotState {
  value: ChatMessage[];
}

const initialState: ChatbotState = {
  value: [] as ChatMessage[],
};

export const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    store: (state, action: PayloadAction<ChatMessage[]>) => {
      state.value = action.payload;
    },
    add: (state, action: PayloadAction<ChatMessage>) => {
      state.value = [...state.value, action.payload];
    },
  },
});

export const { store, add } = chatbotSlice.actions;

export const getChatMessages = async (dispatch: AppDispatch, reset: boolean | undefined) => {
  await getAsync({ route: 'chatbot', data: { reset: !!reset } },
    async (response) => {
      const chatMessages = (await response.obj<ChatMessage[]>()).map((m) => ({
        ...m,
        type: parseEnumValue(m.type, MessageType),
      }));
      dispatch(store(chatMessages));
    });
};

export const sendPrompt = async (dispatch: AppDispatch, prompt: string) => {
  dispatch(add({ content: prompt, type: MessageType.User }));
  await putAsync({ route: 'chatbot', data: { userMessage: prompt } },
    async (response) => { dispatch(add({ content: await response.obj<string>(), type: MessageType.Bot })); },
    async (error) => {
      if (error.httpStatusCode < 500) {
        enqueueSnackbar(error.errorMessage || 'Error communicating with Pathfinder', {
          variant: 'error',
          preventDuplicate: true,
          persist: true,
          action: (key) => (
            <Button size="small" style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
              Dismiss
            </Button>
          ),
        });
        return true;
      }
      return false;
    });
};

export const useChatbot = () => useAppSelector((state: RootState) => state.chatbot.value);

export default chatbotSlice.reducer;
