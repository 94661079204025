enum WorkspaceButtonType {
  None = 0,
  Logoff = 1,
  Home = 2,
  Url = 3,
  KView = 4,
  GlobalKView = 5,
  PartitionAdmin = 6,
  ITAdmin = 7,
  UserProfile = 8,
  eForm = 9,
  Report = 10,
  UrlTop = 11, // Used to navigate the entire browser, not just in the frame
  CloseCaseWorkspaces = 12,
  Chart = 13,
  eFormInstance = 14, // Show form instance in portlet
  Dashboard = 15,
  DataFlowAnalyzer = 16,
  JavaScript = 17,
  HTML = 18,
  Unused = 19, // Unused, formerly DataList (5/9/23)
  ApplicationRun = 20,
  ApplicationModify = 21,
  ApplicationCreate = 22,
}

export default WorkspaceButtonType;
