// Them
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// Us
import {
  IProps, useStepperContext, useValidator,
} from './StepData';
import FolderTree from '../FolderTree';

export default function PickFolder(props: IProps) {
  const { open } = props;
  const [errorSelectedFolder, setErrorSelectedFolder] = useState<string>('');
  const [errorNoFile, setErrorNoFile] = useState(false);
  const {
    selectedFile, setSelectedFile, selectedFolder, setSelectedFolder,
  } = useStepperContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useValidator(props, (enable) => {
    let error = false;
    if (!selectedFolder[0]) {
      setErrorSelectedFolder('Please select a folder');
      error = true;
    } else {
      setErrorSelectedFolder('');
    }
    if (!selectedFile) {
      setErrorNoFile(true);
      error = true;
    } else {
      setErrorNoFile(false);
    }
    return !error;
  }, [selectedFolder, selectedFile]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  return (
    <Box sx={{ typography: 'body1', display: open ? '' : 'none' }}>
      <Grid container columns={{ md: 2 }} sx={{ marginTop: '0rem' }}>
        <Grid xs={1} sx={{ padding: '2rem' }}>
          <Box>
            <Typography sx={{ marginBottom: '2rem', marginTop: '2rem' }}>
              Importing Forms, Knowledge Views, Applications, and more into the system is easy. Simply select the PDZ file you want to import and choose a folder to import it into.
            </Typography>
            {selectedFile && (
              <Typography sx={{ mb: '1rem' }}>
                {selectedFile.name}
              </Typography>
            )}
            <Button variant="contained" component="label" data-cy="PickFolder_UploadFile">
              Upload File
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
            <Typography sx={{ color: (theme) => theme.palette.error.main }}>
              {errorNoFile && 'Please choose a file to proceed.'}
            </Typography>
            <TextField
              aria-label="selected folder"
              label="Selected Folder"
              helperText={errorSelectedFolder || 'Select a folder in the tree'}
              error={!!errorSelectedFolder}
              InputProps={{
                readOnly: true,
              }}
              value={selectedFolder[1]}
              variant="standard"
              data-cy="PickFolder_SelectedFolder"
              sx={{ width: '80%', mb: '1rem', mt: '1rem' }}
            />
          </Box>
        </Grid>
        <Grid
          xs={1}
          sx={{
            height: '55vh', overflow: 'auto',
          }}
        >
          <FolderTree onSelectFolder={setSelectedFolder} selected={selectedFolder[0]} />
        </Grid>
      </Grid>
    </Box>
  );
}
