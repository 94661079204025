// Them
import React, { createContext, useState } from 'react';

// Us
import FlattenedContentItem from '@components/layout/implementation/FlattenedContentItem';

export type WidgetState = {
  open: boolean;
  pinned: boolean;
  list: FlattenedContentItem[];
  filteredList: FlattenedContentItem[];
  selectedId: string;
  setOpen: (open: boolean) => void;
  setPinned: (open: boolean) => void;
  setList: (list: FlattenedContentItem[]) => void;
  setFilteredList: (list: FlattenedContentItem[]) => void;
  setSelectedId: (id: string) => void;
};

export const ContentListWidgetStateContext = createContext<WidgetState>({
  open: false,
  pinned: false,
  list: [],
  filteredList: [],
  selectedId: '',
  setOpen: (open: boolean) => {},
  setPinned: (open: boolean) => {},
  setList: (list: FlattenedContentItem[]) => {},
  setFilteredList: (list: FlattenedContentItem[]) => {},
  setSelectedId: (id: string) => {},
});

interface IProps {
  children?: React.ReactNode
}

export default function ContentListWidgetStateProvider({ children }: IProps) {
  const [open, setOpen] = useState(false);
  const [pinned, setPinned] = useState(false);
  const [list, setList] = React.useState<FlattenedContentItem[]>([]);
  const [filteredList, setFilteredList] = React.useState<FlattenedContentItem[]>([]);
  const [selectedId, setSelectedId] = useState('');

  return (
    <ContentListWidgetStateContext.Provider value={{
      open,
      pinned,
      list,
      filteredList,
      selectedId,
      setOpen,
      setPinned,
      setList,
      setFilteredList,
      setSelectedId,
    }}
    >
      {children ?? <></>}
    </ContentListWidgetStateContext.Provider>
  );
}
