import React, { useEffect, useState, useContext } from 'react';
import Drawer from '@mui/material/Drawer';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Icon from '@mui/material/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

// Us
import ExpandAllIcon from '@components/icons/ExpandAll';
import ClearIcon from '@mui/icons-material/Clear';
import CollapseAllIcon from '@components/icons/CollapseAll';
import ContentItem from '@models/ContentItem';
import VirtualTree, { ExpandStates } from '@components/layout/VirtualTree';
import { ContentListWidgetStateContext } from '@components/layout/ContentListWidgetState';
import { CustomTheme } from '@components/layout/Theme';
import { StyledIconButton } from './StyledButtons';
import filters from './implementation/filters';

const useStyles = makeStyles((theme: CustomTheme) => ({
  spacer: {
    minHeight: `calc(${theme.mixins.toolbar.minHeight}px - 1px)`,
    '@media (min-width: 600px)': {
      minHeight: `calc(${theme.mixins.toolbar.minHeight}px - 1px)`,
    },
    '@media (min-width: 0px) and (orientation: landscape)': {
      minHeight: `calc(${theme.mixins.toolbar.minHeight}px - 1px)`,
    },
    clearButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      zIndex: 1,
    },
  },
  treeViewRoot: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
    overflow: 'auto',
  },
  drawerContentList: {
    color: '#000000',
    whiteSpace: 'nowrap',
    border: '1px solid #116EB6',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    overflow: 'hidden',
  },
  drawerCloseContentList: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
  drawerPaperContentList: {
    color: '#000000',
    backgroundColor: '#fff',
    border: '1px solid #116EB6',
    minWidth: '30rem',
    overflow: 'hidden !important',
  },
  inputFilter: {
    border: 'none',
    borderRadius: '10px',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    fontSize: theme.typography.body2.fontSize,
  },
}));

const StyledInput = styled(InputBase)(({ theme }: { theme: CustomTheme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 4,
  border: '1px solid #116EB6',
}));

interface OwnProps {
  contentList?: ContentItem[];
  includeSystemFolders: boolean;
  toggleSystemFolders(): void;
}

interface FilterSelection {
  id: string;
  name: string;
  icon: string;
  checked: boolean;
  typeId?: number;
}

export default function ContentList(props: React.PropsWithChildren<OwnProps>) {
  const {
    contentList, includeSystemFolders, toggleSystemFolders,
  } = props;

  const classes = useStyles();
  const [expandState, setExpandState] = useState<ExpandStates>(ExpandStates.Indeterminate);
  const [filterPhrase, setFilterPhrase] = useState<string>('');
  const [filtering, setFiltering] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFilters, setSelectedFilters] = useState<FilterSelection[]>(filters);
  const [selectedFiltersTypes, setSelectedFiltersTypes] = useState<number[]>([]);
  const [expandAll, setExpandAll] = useState<boolean>(false);
  const [pinnedStateChanging, setPinnedStateChanging] = useState<boolean>(false);
  const {
    open, pinned, setOpen, setPinned,
  } = useContext(ContentListWidgetStateContext);

  useEffect(() => {
    setTimeout(() => {
      if (pinnedStateChanging) {
        setPinnedStateChanging(false);
      }
    }, 300);
  }, [pinnedStateChanging]);

  const togglePinned = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    setPinned(!pinned);
    setPinnedStateChanging(true);
  };
  const handleExpandAllClick = () => {
    setExpandAll(!expandAll);
    setExpandState(expandAll ? ExpandStates.CollapseAll : ExpandStates.ExpandAll);
  };
  const toggleFiltering = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setFiltering(!filtering);
  };
  const handleFilteringClose = () => {
    setAnchorEl(null);
    setFiltering(!filtering);
  };
  const handleFilteringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterPhrase(event.target.value);
  };
  const handleClearFilter = () => {
    setFilterPhrase('');
  };
  const handleFilterClick = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters = selectedFilters.map((filter) => {
      if (filter.id === id) {
        const newFilter = { ...filter };
        newFilter.checked = event.target.checked;
        return newFilter;
      }
      return filter;
    });
    setSelectedFiltersTypes(newFilters.filter((filter) => filter.checked).map((filter) => filter.typeId as number));
    setSelectedFilters(newFilters);
  };
  const handleDrawerClose = () => {
    if (open) {
      setOpen(false);
    }
  };

  return (
    <Drawer
      open={open}
      variant={pinned ? 'persistent' : 'temporary'}
      className={clsx(classes.drawerContentList, classes.drawerCloseContentList)}
      classes={{ paper: clsx(classes.drawerCloseContentList) }}
      PaperProps={{ className: clsx(classes.drawerPaperContentList) }}
      onClose={handleDrawerClose}
      transitionDuration={pinnedStateChanging ? 0 : 300}
      id="ContentList"
    >
      <Box display="flex" alignItems="center" justifyContent="flex-end" px="1" py="0" className={classes.spacer} />
      <Box bgcolor="#082F4D" color="#fff" borderRadius="0">
        <Box margin=".25rem .5rem .25rem .5rem">
          <Box display="flex" flexDirection="row" justifyContent="flex-start">
            <Typography
              variant="body1"
              component="div"
              sx={{
                flexGrow: 1, margin: '2px', fontSize: '14px', cursor: 'default', transform: 'translateY(15%)',
              }}
            >
              Content List
            </Typography>
            <StyledIconButton size="small" onClick={handleExpandAllClick} data-cy="ToggleExpandCollapse">
              <Tooltip title={expandAll ? 'Collapse All' : 'Expand All'}>
                <Box>
                  {expandAll ? <CollapseAllIcon /> : <ExpandAllIcon />}
                </Box>
              </Tooltip>
            </StyledIconButton>
            <StyledIconButton size="large" value="pinned" onClick={toggleSystemFolders} style={{ marginLeft: 'auto', marginRight: 0, alignSelf: 'flex-end' }} data-cy="ToggleSystemFolders">
              <Tooltip title="Toggle System Folders">
                <Box>
                  {includeSystemFolders ? <AccountTreeIcon /> : <AccountTreeOutlinedIcon />}
                </Box>
              </Tooltip>
            </StyledIconButton>
            <StyledIconButton size="large" value="pinned" style={{ marginLeft: 'auto', marginRight: 0, alignSelf: 'flex-end' }} onClick={(e) => togglePinned(e)}>
              <Tooltip title="Pin Content List">
                <Box>
                  {pinned ? <PushPinIcon /> : <PushPinOutlinedIcon />}
                </Box>
              </Tooltip>
            </StyledIconButton>
          </Box>
        </Box>
        <Box margin=".25rem .5rem .25rem .5rem">
          <Box display="flex" flexDirection="row" justifyContent="flex-start">
            <Box
              display="flex"
              alignItems="center"
              position="relative"
              width="100%"
              marginTop=".25rem"
            >
              <StyledInput
                placeholder="Enter filter phrase"
                fullWidth
                inputProps={{ className: classes.inputFilter }}
                value={filterPhrase}
                sx={{ paddingLeft: '0.25rem', paddingRight: '0.25rem' }}
                onChange={handleFilteringChange}
                endAdornment={
                  filterPhrase ? (
                    <ClearIcon
                      className={classes.clearButton}
                      sx={{ cursor: 'pointer' }}
                      onClick={handleClearFilter}
                    />
                  ) : null
                }
              />
            </Box>
            <StyledIconButton
              onClick={toggleFiltering}
              style={{
                width: '60px', backgroundColor: '#D9D9D9', marginLeft: '5px', marginTop: '.25rem', borderRadius: '10px',
              }}
              value="filtered"
              data-cy="ObjectTypeDropdown"
            >
              <Tooltip title="Filtering">
                <>
                  {filtering ? <FilterAltIcon style={{ color: '#092840' }} /> : <FilterAltOutlinedIcon style={{ color: '#092840' }} />}
                  <ArrowDropDownIcon style={{ color: '#092840' }} />
                </>
              </Tooltip>
            </StyledIconButton>
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              onClose={handleFilteringClose}
            >
              {selectedFilters.map((filter) => (
                <MenuItem key={filter.id}>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={filter.checked} onChange={(e) => handleFilterClick(filter.id, e)} />}
                    label={(
                      <>
                        <Icon key={filter.id} fontSize="small" sx={{ marginRight: '5px', verticalAlign: 'middle' }}>{filter.icon}</Icon>
                        {' '}
                        {filter.name}
                      </>
                    )}
                  />
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
      </Box>
      <Box flexGrow="1" data-cy="ContentList">
        <VirtualTree
          contentList={contentList}
          filterPhrase={filterPhrase}
          selectedTypes={selectedFiltersTypes}
          includeSystemFolders={includeSystemFolders}
          expandState={expandState}
          setExpandState={setExpandState}
        />
      </Box>
    </Drawer>
  );
}
