// Them
import React, { useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

// Us
import { postAsync } from '@components/data/rest';
import { navContent } from '@components/utilities/Navigation';
import { schemeServerBasePath } from '@components/utilities/Paths';
import TemplateObject from '@models/TemplateObject';
import PickFolder from './PickFolder';
import PickTemplate from './PickTemplate';
import Create from './Create';
import { IMap, StepperContext } from './StepData';
import { getFriendlyError } from '../StepperCommon';

interface IStep {
  name: string;
  component: (stepNum: number, open: boolean, dataMap: IMap) => React.ReactNode;
}

const steps: IStep[] = [
  {
    name: 'Choose Template',
    component: (stepNum, open, validators) => <PickTemplate key="templ" stepNum={stepNum} open={open} validators={validators} />,
  },
  {
    name: 'Select Folder',
    component: (stepNum, open, validators) => <PickFolder key="folder" stepNum={stepNum} open={open} validators={validators} />,
  },
  {
    name: 'Create',
    component: (stepNum, open, validators) => <Create key="create" stepNum={stepNum} open={open} validators={validators} />,
  },
];

interface IProps {
  open: boolean;
  onClose: () => void;
  templates: TemplateObject[];
}

export default function FromTemplateDialog(props: IProps) {
  const { onClose, open, templates } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean | undefined>(undefined);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState('');
  const [resultUrl, setResultUrl] = React.useState('');
  const dataMap = useRef<IMap>({});
  const history = useHistory();
  // Stepper shared data
  //   PickTemplate
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  //   PickFolder
  const [applicationName, setApplicationName] = useState<string>('');
  const [selectedFolder, setSelectedFolder] = useState<string[]>(['', '']);
  const [newFolder, setNewFolder] = useState<string>('');

  const handleNext = () => {
    const validator = dataMap.current[activeStep];
    if (validator) {
      const validated = validator.validate(true);
      if (validated) {
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const postData = useCallback(async () => {
    await postAsync({
      route: 'Applications',
      data: {
        templateId: selectedTemplate,
        applicationName,
        parentFolderId: selectedFolder![0],
        newFolder,
      },
    }, async (response) => {
      setSuccess(true);
      setLoading(false);
      setError('');
      setResultUrl(response.response.headers.get('Location')!);
    },
    async (apiError) => {
      setSuccess(false);
      setLoading(false);
      setError(getFriendlyError(apiError));
      return true;
    });
  }, [applicationName, newFolder, selectedFolder, selectedTemplate]);

  const handleCreate = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      postData();
    }
  };

  const handleClose = () => {
    onClose();
    const url = resultUrl.replace(schemeServerBasePath, '');
    navContent(history, url);
  };

  return (
    <StepperContext.Provider value={{
      success,
      loading,
      error,
      activeStep,
      templates,
      selectedTemplate,
      setSelectedTemplate,
      applicationName,
      setApplicationName,
      selectedFolder,
      setSelectedFolder,
      newFolder,
      setNewFolder,
    }}
    >
      <Dialog
        fullScreen={fullScreen}
        fullWidth={!fullScreen}
        maxWidth={fullScreen ? 'sm' : 'lg'}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Step key={step.name} {...stepProps}>
                  { /* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <StepLabel {...labelProps} id="responsive-dialog-title">{step.name}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <DialogContent sx={{ pb: 0, height: '55vh' }}>
          {steps.map((step, index) => step.component(index, index === activeStep, dataMap.current))}
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {activeStep < steps.length && !success && (
              <Button
                color="inherit"
                disabled={activeStep === 0 || loading}
                onClick={handleBack}
                sx={{ mr: 1 }}
                data-cy="FromTemplate_Back"
              >
                Back
              </Button>
            )}
            <Box sx={{ flex: '1 1 auto' }} />
            {(activeStep < steps.length - 1) && (
              <Button onClick={handleNext} data-cy="FromTemplate_Next">
                Next
              </Button>
            )}
            {(activeStep === steps.length - 1) && !success && (
              <Box sx={{ position: 'relative' }}>
                <Button onClick={handleCreate} disabled={loading} data-cy="FromTemplate_Create">
                  Create
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
            )}
            {(activeStep === steps.length - 1) && success && (
              <Button onClick={handleClose} data-cy="FromTemplate_Close">Close</Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </StepperContext.Provider>
  );
}
