// Them
import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Button from '@mui/material/Button';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

// Us
import ContentItem from '@models/ContentItem';
// eslint-disable-next-line import/no-cycle
import { RootState, AppDispatch } from '@store/store';
import { useAppSelector } from '@store/hooks';
// eslint-disable-next-line import/no-cycle
import { getAsync, putAsync } from '@components/data/rest';

export interface ContentListState {
  value: ContentItem[];
  includeSystemFolders: boolean;
}

const initialState: ContentListState = {
  value: [],
  includeSystemFolders: false,
};

function findItem(contentItems: ContentItem[], id: string): ContentItem | undefined {
  let item: ContentItem | undefined;
  contentItems?.forEach((contentItem) => {
    if (!item) {
      if (contentItem.id === id) {
        item = contentItem;
        return;
      }
      item = findItem(contentItem.children, id);
    }
  });
  return item;
}

export const contentListSlice = createSlice({
  name: 'contentList',
  initialState,
  reducers: {
    store: (state, action: PayloadAction<ContentItem[]>) => {
      state.value = action.payload;
    },
    storeSystemFolderState: (state, action: PayloadAction<boolean>) => {
      state.includeSystemFolders = action.payload;
    },
    storeFavorite: (state, action: PayloadAction<ContentItem>) => {
      const contentItem = action.payload;
      if (state.value && state.value.length > 0) {
        const item = findItem(state.value, contentItem.id);
        if (item) {
          item.favorite = contentItem.favorite;
        }
      }
    },
  },
});

export const { store, storeSystemFolderState, storeFavorite } = contentListSlice.actions;

export const getContentList = async (dispatch: AppDispatch) => {
  await getAsync('ContentList',
    async (response) => {
      const contentList = await response.obj<ContentItem[]>();
      dispatch(store(contentList));
    });
};

export const updateFavoriteAsync = async (dispatch: AppDispatch, contentItem: ContentItem) => {
  putAsync({ route: `UserProfiles/Favorite/${contentItem.id}/${contentItem.favorite}` }, async (response) => {
    await response.response.body?.cancel();
    dispatch(storeFavorite(contentItem));
  }, async (error) => {
    if (error.httpStatusCode < 500) {
      enqueueSnackbar(error.errorMessage || 'Error updating favorite', {
        variant: 'error',
        preventDuplicate: true,
        persist: true,
        action: (key) => (
          <Button size="small" style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
            Dismiss
          </Button>
        ),
      });
      return true;
    }
    return false;
  });
};

export const useContentList = () => useAppSelector((state: RootState) => state.contentList.value);
export const useIncludeSystemFolders = () => useAppSelector((state: RootState) => state.contentList.includeSystemFolders);

export default contentListSlice.reducer;
