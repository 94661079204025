// Them
import React, { useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';

// Us
import { postAsync } from '@components/data/rest';
import { navContent } from '@components/utilities/Navigation';
import { schemeServerBasePath } from '@components/utilities/Paths';
import { FormDefTypes } from '@models/CreateFormDef';
import ObjectType from '@models/ObjectType';
import { ReportTypes } from '@models/CreateReport';
import PickFolder from './PickFolder';
import PickObject from './PickObject';
import Create from './Create';
import {
  objects, IMap, StepperContext,
} from './StepData';
import { getFriendlyError } from '../StepperCommon';

interface IStep {
  name: string;
  component: (stepNum: number, open: boolean, dataMap: IMap) => React.ReactNode;
}

const steps: IStep[] = [
  {
    name: 'Choose Object',
    component: (stepNum, open, validators) => <PickObject key="templ" stepNum={stepNum} open={open} validators={validators} />,
  },
  {
    name: 'Select Folder',
    component: (stepNum, open, validators) => <PickFolder key="folder" stepNum={stepNum} open={open} validators={validators} />,
  },
  {
    name: 'Create',
    component: (stepNum, open, validators) => <Create key="create" stepNum={stepNum} open={open} validators={validators} />,
  },
];

interface IProps {
  open: boolean;
  onClose: () => void;
}

export default function CreateObjectDialog(props: IProps) {
  const { onClose, open } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const dataMap = useRef<IMap>({});
  const history = useHistory();
  const [resultUrl, setResultUrl] = useState('');
  // Stepper shared data
  //    Settings
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  //    PickObject
  const [selectedObject, setSelectedObject] = useState<string>('');
  const [formDefSelected, formDefSetSelected] = useState<FormDefTypes>(FormDefTypes.None);
  const [reportSelected, reportSetSelected] = useState<ReportTypes>(ReportTypes.Dashboard);
  //    PickFolder
  const [objectName, setObjectName] = useState<string>(''); // Move me to StepData
  const [fileSelectedFile, fileSetSelectedFile] = useState<File | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<string[]>(['', '']);

  const handleNext = () => {
    const validator = dataMap.current[activeStep];
    if (validator) {
      const validated = validator.validate(true);
      if (validated) {
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const postData = useCallback(async () => {
    const data = {
      name: objectName,
      parentFolderId: selectedFolder![0],
      type: objects.find((o) => o.name === selectedObject)!.type,
    } as any;
    switch (data.type) {
      case ObjectType.Form:
        data.formDefTypes = formDefSelected;
        break;
      case ObjectType.Report:
        data.reportTypes = reportSelected;
        break;
      default:
        break;
    }
    const { endpoint } = objects.find((o) => o.name === selectedObject)!;
    if (data.type === ObjectType.Document) {
      const formData = new FormData();
      formData.append('json', new Blob([JSON.stringify(data)], { type: 'application/json' }));
      formData.append('file', fileSelectedFile!, selectedObject);
      await postAsync({
        route: endpoint,
        data: formData,
      }, async (response) => {
        setSuccess(true);
        setLoading(false);
        setError('');
        setResultUrl(response.response.headers.get('Location')!);
      },
      async (apiError) => {
        setSuccess(false);
        setLoading(false);
        setError(getFriendlyError(apiError));
        return true;
      });
    } else {
      await postAsync({
        route: endpoint,
        data,
      }, async (response) => {
        setSuccess(true);
        setLoading(false);
        setError('');
        setResultUrl(response.response.headers.get('Location')!);
      },
      async (apiError) => {
        setSuccess(false);
        setLoading(false);
        setError(getFriendlyError(apiError));
        return true;
      });
    }
  }, [fileSelectedFile, formDefSelected, objectName, reportSelected, selectedFolder, selectedObject]);

  const handleCreate = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      postData();
    }
  };

  const handleClose = () => {
    onClose();
    const url = resultUrl.replace(schemeServerBasePath, '');
    navContent(history, url);
  };

  return (
    <StepperContext.Provider value={{
      success,
      loading,
      error,
      activeStep,
      selectedObject,
      setSelectedObject,
      objectName,
      setObjectName,
      formDefOptions: {
        selected: formDefSelected,
        setSelected: formDefSetSelected,
      },
      reportOptions: {
        selected: reportSelected,
        setSelected: reportSetSelected,
      },
      fileOptions: {
        selectedFile: fileSelectedFile,
        setSelectedFile: fileSetSelectedFile,
      },
      selectedFolder,
      setSelectedFolder,
    }}
    >
      <Dialog
        fullScreen={fullScreen}
        fullWidth={!fullScreen}
        maxWidth={fullScreen ? 'sm' : 'lg'}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
              // eslint-disable-next-line react/jsx-props-no-spreading
                <Step key={step.name} {...stepProps}>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <StepLabel {...labelProps} id="responsive-dialog-title">{step.name}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <DialogContent sx={{ pb: 0, height: '55vh' }}>
          {steps.map((step, index) => step.component(index, index === activeStep, dataMap.current))}
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {activeStep < steps.length && !success && (
              <Button
                color="inherit"
                disabled={activeStep === 0 || loading}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
            )}
            <Box sx={{ flex: '1 1 auto' }} />
            {(activeStep < steps.length - 1) && (
              <Button onClick={handleNext} data-cy="CreateObject_Next">
                Next
              </Button>
            )}
            {(activeStep === steps.length - 1) && !success && (
              <Box sx={{ position: 'relative' }}>
                <Button onClick={handleCreate} disabled={loading} data-cy="CreateObject_Create">
                  Create
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
            )}
            {(activeStep === steps.length - 1) && success && (
              <Button onClick={handleClose} data-cy="CreateObject_Close">Close</Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </StepperContext.Provider>
  );
}
