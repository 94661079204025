export enum IconType {
  NoIcon = 0,
  OgIcon
}

export interface OgIconData {
  id: number;
  color: string;
}

export default interface Icon {
  type: IconType;
  data: any;
}
