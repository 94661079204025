// Them
import React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

// Us
import { ReportTypes } from '@models/CreateReport';
import { useStepperContext } from './StepData';

interface IReportOptions {
  name: string;
  detail: string;
  type: ReportTypes;
}

const formDefOptions: IReportOptions[] = [
  {
    name: 'Dashboard',
    detail: 'Provides at-a-glance views of key performance indicators (KPIs) relevant to a particular objective or business process.',
    type: ReportTypes.Dashboard,
  },
  {
    name: 'Multi-page',
    detail: 'Provides paginated output of data, charts, and graphics to be printed or exported to other formats.',
    type: ReportTypes.Multipage,
  },
  {
    name: 'Portlet',
    detail: 'Provides data, charts, and graphics suitable for inclusion in Workspace portlets.',
    type: ReportTypes.Portlet,
  },
];

export default function ReportDetails() {
  const { reportOptions: { selected, setSelected } } = useStepperContext();

  return (
    <>
      <Typography>
        Choose your report type.
      </Typography>
      <List sx={{ maxHeight: '15rem', overflow: 'auto' }} component="div">
        {formDefOptions.map((option) => (
          <ListItemButton alignItems="flex-start" key={option.name} selected={selected === option.type} onClick={() => setSelected(option.type)}>
            <ListItemText
              primary={option.name}
              secondary={option.detail}
            />
          </ListItemButton>
        ))}
      </List>
    </>
  );
}
