// Them
import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Button from '@mui/material/Button';
import rfdc from 'rfdc';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

// Us
import UserProfile from '@models/UserProfile';
import DefaultPage from '@models/DefaultPage';
// eslint-disable-next-line import/no-cycle
import { RootState, AppDispatch } from '@store/store';
import { useAppSelector } from '@store/hooks';
// eslint-disable-next-line import/no-cycle
import { getAsync, putAsync } from '@components/data/rest';
import { parseEnumValue } from '@components/utilities/enums';

export interface UserProfileState {
  value: UserProfile;
}

const initialState: UserProfileState = {
  value: {} as UserProfile,
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    store: (state, action: PayloadAction<UserProfile>) => {
      const payload = rfdc()(action.payload);
      payload.defaultPage = parseEnumValue(action.payload.defaultPage, DefaultPage);
      state.value = payload;
    },
    updateDefaultPage: (state, action: PayloadAction<DefaultPage>) => {
      const userProfileClone = rfdc()(state.value);
      userProfileClone.defaultPage = action.payload;
      state.value = userProfileClone;
    },
  },
});

export const { store, updateDefaultPage } = userProfileSlice.actions;

export const setDefaultPageAsync = async (dispatch: AppDispatch, defaultPage: DefaultPage) => {
  putAsync({ route: `UserProfiles/DefaultPage/${defaultPage}` }, async (response) => {
    await response.response.body?.cancel();
    dispatch(updateDefaultPage(defaultPage));
  }, async (error) => {
    if (error.httpStatusCode < 500) {
      enqueueSnackbar(error.errorMessage || 'Error updating default page', {
        variant: 'error',
        preventDuplicate: true,
        persist: true,
        action: (key) => (
          <Button size="small" style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
            Dismiss
          </Button>
        ),
      });
      return true;
    }
    return false;
  });
};

export const getUserProfileAsync = async (dispatch: AppDispatch) => {
  await getAsync('UserProfiles/CurrentUser',
    async (response) => {
      const userProfile = await response.obj<UserProfile>();
      dispatch(store(userProfile));
    });
};

export const storeUserProfile = async (dispatch: AppDispatch, userProfile: UserProfile) => {
  dispatch(store(userProfile));
};

export const useUserProfile = () => useAppSelector((state: RootState) => state.userProfile.value);

export default userProfileSlice.reducer;
