import React, { useEffect, useState, createContext } from 'react';

export const FontLoadingContext = createContext(false);

interface IProps {
  children: React.ReactNode;
}

export function FontLoadingContextWrapper({ children }: IProps) {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    if (document.fonts.check('1em Material Icons')) {
      setFontLoaded(true);
    } else {
      document.fonts.ready.then(() => {
        setFontLoaded(true);
      });
    }
  }, []);

  return (
    <FontLoadingContext.Provider value={fontLoaded}>
      {children}
    </FontLoadingContext.Provider>
  );
}
