// Them
import React, {
  useEffect, useRef, useState,
} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import rfdc from 'rfdc';

// Us
import { useContentList } from '@store/contentListSlice';
import ContentItem from '@models/ContentItem';
import ObjectType from '@models/ObjectType';

function folderFilter(contentItems: ContentItem[], addIds: boolean): [ContentItem[], string[]] {
  const folders = rfdc()(contentItems
    .filter((contentItem) => Number(ObjectType[contentItem.type]) === ObjectType.Folder && !(contentItem.name.startsWith('[') && contentItem.name.endsWith(']'))));
  const ids = folders.map((folder) => folder.id);
  folders.forEach((folder) => {
    const [children, childIds] = folderFilter(folder.children, false);
    // eslint-disable-next-line no-param-reassign
    folder.children = children;
    ids.push(...childIds);
  });
  return [folders, addIds ? ids : []];
}

function findLabel(folders: ContentItem[], id: string): string {
  let label = '';
  folders.forEach((folder) => {
    if (folder.id === id) {
      label = folder.name;
    } else if (folder.children) {
      const childLabel = findLabel(folder.children, id);
      if (childLabel) {
        label = childLabel;
      }
    }
  });
  return label;
}

interface IProps {
  onSelectFolder: (folder: [string, string]) => void;
  selected: string;
}

export default function FolderTree(props: IProps) {
  const { onSelectFolder, selected } = props;
  const contentList = useContentList();
  const [folders, setFolders] = useState<ContentItem[] | undefined>(undefined);
  const [expanded, setExpanded] = useState<string[]>([]);
  const treeView = useRef(null);

  useEffect(() => {
    const [foldersTemp, ids] = folderFilter(contentList, true);
    setFolders(foldersTemp);
    setExpanded(ids);
  }, [contentList]);

  const renderTree = (folder: ContentItem) => (
    <TreeItem
      key={folder.id}
      nodeId={folder.id}
      label={folder.name}
    >
      {Array.isArray(folder.children)
        ? folder.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <>
      {folders && (
        <TreeView
          ref={treeView}
          aria-label="folder tree"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          sx={{ whiteSpace: 'nowrap' }}
          selected={selected}
          data-cy="FolderTree"
          onNodeSelect={(e, id) => {
            if (!e.target.closest('svg')) {
              onSelectFolder([id, findLabel(folders, id)]);
            }
          }}
          onNodeToggle={(e, ids) => {
            if (e.target.closest('svg')) {
              setExpanded(ids);
            }
          }}
        >
          {folders.map((folder) => renderTree(folder))}
        </TreeView>
      )}
    </>
  );
}
