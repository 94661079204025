import { createContext, useContext, DependencyList } from 'react';
import ImportResult from '@models/ImportResult';
import {
  IMap, IStepperContext, IProps, useValidator as useValidatorBase,
} from '../StepperCommon';

export type { IMap, IProps };

export interface IImportStepperContext extends IStepperContext {
  // Data
  selectedFolder: string[];
  setSelectedFolder: (selectedFolder: string[]) => void;
  selectedFile: File | null;
  setSelectedFile: (selectedFile: File) => void;
  importResults: ImportResult[];
}

export const StepperContext = createContext<IImportStepperContext>({ success: false, activeStep: 0 } as IImportStepperContext);

export function useStepperContext(): IImportStepperContext {
  return useContext(StepperContext);
}

export function useValidator(props: IProps, validator: (enable?: boolean) => boolean, deps?: DependencyList) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useValidatorBase(useStepperContext(), props, validator, deps);
}
