// Them
import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

// Us
import { IProps, StepperContext, useValidator } from './StepData';
import ImportResults from './ImportResults';

export default function Review(props: IProps) {
  const { open } = props;
  const {
    loading, success, error, selectedFile, importResults,
  } = useContext(StepperContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useValidator(props, (enable) => true, []);

  const bgPicker = (successColor: string, errorColor: string, loadingColor: string) => {
    if (loading) {
      return loadingColor;
    } if (success) {
      return successColor;
    }
    return errorColor;
  };

  let statusWidgetStyling = {};
  if (!loading && success) {
    statusWidgetStyling = {
      marginTop: 0, opacity: 0, height: 0, transition: 'opacity 0.5s ease-out, margin-top 0.5s ease-out, height 0.5s ease-out 0.5s',
    };
  } else if (!loading && !success) {
    statusWidgetStyling = {
      marginTop: '3rem', transition: 'margin-top 0.5s ease-out, height 0.5s ease-out',
    };
  } else {
    statusWidgetStyling = { marginTop: '10rem' };
  }

  return (
    <Box sx={{ typography: 'body1', display: open ? '' : 'none' }}>
      {success && (
        <Typography sx={{ mt: '1rem', mb: '.5rem' }}>
          <span style={{ backgroundColor: 'lightgray', paddingLeft: '.2rem', paddingRight: '.2rem' }}>{selectedFile?.name}</span>
          &nbsp;imported. Review import results below. Click Close to view imported objects.
        </Typography>
      )}
      {loading !== undefined && (
        <Box sx={{
          display: 'grid', placeItems: 'center',
        }}
        >
          <Box sx={{
            ...statusWidgetStyling,
          }}
          >
            <Box sx={{
              position: 'relative',
              display: 'grid',
              placeItem: 'center',
            }}
            >
              <Box sx={{
                color: 'white', width: 56, height: 56, borderRadius: '50%', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', backgroundColor: (theme) => bgPicker(green[500], red[500], theme.palette.primary.main),
              }}
              >
                {loading && <SaveIcon />}
                {!loading && (success ? <CheckIcon /> : <ErrorOutline />)}
              </Box>
              {loading && (
                <CircularProgress
                  size={68}
                  sx={{
                    position: 'absolute',
                    top: -6,
                    left: -6,
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
            <Typography variant="subtitle1" sx={{ mt: '.5rem' }}>
              {loading && 'Importing...'}
              {!loading && (success ? 'Imported' : 'Failed')}
            </Typography>
          </Box>
          {error && (
            <>
              <Typography sx={{ mt: '3rem' }}>
                A problem was encountered while importing the file. The error message follows:
              </Typography>
              <Typography sx={{ mt: '1rem', color: (theme) => theme.palette.error.main }}>
                {error}
              </Typography>
              <Typography sx={{ mt: '1rem' }}>
                You can attempt to retry or cancel by clicking below.
              </Typography>
            </>
          )}
        </Box>
      )}
      {!loading && success && (
        <ImportResults data={importResults} />
      )}
    </Box>
  );
}
