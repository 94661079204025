// Them
import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Button from '@mui/material/Button';
import rfdc from 'rfdc';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

// Us
import Workspace from '@models/Workspace';
// eslint-disable-next-line import/no-cycle
import type { RootState, AppDispatch } from '@store/store';
import { useAppSelector } from '@store/hooks';
// eslint-disable-next-line import/no-cycle
import { getAsync, putAsync } from '@components/data/rest';

export interface WorkspacesState {
  value: Workspace[];
}

const initialState: WorkspacesState = {
  value: [],
};

export const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    store: (state, action: PayloadAction<Workspace[]>) => {
      state.value = action.payload;
    },
    updateActive: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const wsClone = rfdc()(state.value);
      // It's possible for new users to not have an active workspace yet.
      const oldActive = wsClone.find((ws) => ws.isActive);
      const newActive = wsClone.find((ws) => ws.id === id);
      if (newActive) {
        newActive!.isActive = true;
      }
      if (oldActive && newActive) {
        oldActive!.isActive = false;
      }
      state.value = wsClone;
    },
  },
});

export const { store, updateActive } = workspacesSlice.actions;

export const setActiveWorkspaceAsync = async (dispatch: AppDispatch, id: string) => {
  putAsync({ route: `Workspaces/${id}/active` }, async (response) => {
    await response.response.body?.cancel();
    dispatch(updateActive(id));
  }, async (error) => {
    if (error.httpStatusCode < 500) {
      enqueueSnackbar(error.errorMessage || 'Error updating active workspace', {
        variant: 'error',
        preventDuplicate: true,
        persist: true,
        action: (key) => (
          <Button size="small" style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
            Dismiss
          </Button>
        ),
      });
      return true;
    }
    return false;
  });
};

export const getWorkspaces = async (dispatch: AppDispatch) => {
  await getAsync('Workspaces',
    async (response) => {
      const workspaces = await response.obj<Workspace[]>();
      dispatch(store(workspaces));
    });
};

export const useWorkspaces = () => useAppSelector((state: RootState) => state.workspaces.value);

export default workspacesSlice.reducer;
