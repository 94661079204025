import { useEffect } from 'react';
import { useProduct } from '@store/productSlice';

export default function PageTitle(props: any) {
  const { title } = props;
  const product = useProduct();
  useEffect(() => {
    document.title = title ? `${title} - ${product ? product.title : ''}` : title;
  }, [product, title]);

  return props.children;
}
