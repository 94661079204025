/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
const _interopRequireDefault = require('@babel/runtime/helpers/interopRequireDefault');

const _interopRequireWildcard = require('@babel/runtime/helpers/interopRequireWildcard');

Object.defineProperty(exports, '__esModule', {
  value: true,
});
// eslint-disable-next-line no-void
exports.default = void 0;

const React = _interopRequireWildcard(require('react'));

const _createSvgIcon = _interopRequireDefault(require('@mui/material/utils/createSvgIcon'));

const _default = (0, _createSvgIcon.default)(/* #__PURE__ */React.createElement('path', {
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  // eslint-disable-next-line max-len
  d: 'M4 2H14V4H4V14H2V4C2 2.9 2.89 2 4 2ZM8 6H18V8H8V18H6V8C6 6.9 6.89 6 8 6ZM12 10H20C21.1 10 22 10.9 22 12V20C22 21.1 21.1 22 20 22H12C10.89 22 10 21.1 10 20V12C10 10.9 10.89 10 12 10ZM14.9999 11.9999H16.9949V14.9999H19.9999V16.9999H16.9949V19.9949H14.9999V16.9999H11.9999V14.9999H14.9999V11.9999Z',
}), 'Accessibility');

exports.default = _default;
