// Them
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Us
import ResourceMap from '@models/ResourceMap';
// eslint-disable-next-line import/no-cycle
import { RootState, AppDispatch } from '@store/store';
import { useAppSelector } from '@store/hooks';
// eslint-disable-next-line import/no-cycle
import { getAsync } from '@components/data/rest';

export interface ResourcesState {
  value: ResourceMap;
}

const initialState: ResourcesState = {
  value: {} as ResourceMap,
};

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    store: (state, action: PayloadAction<ResourceMap>) => {
      state.value = action.payload;
    },
  },
});

export const { store } = resourcesSlice.actions;

export const getResources = async (dispatch: AppDispatch) => {
  await getAsync('Resources/enums',
    async (response) => {
      const result = await response.obj<ResourceMap>();
      const mappings = Object.fromEntries(Object.entries(result).map(([k, v]) => [k.toLowerCase(), v]));
      dispatch(store(mappings));
    });
};

export const selectResources = (state: RootState) => state.resources.value;
export const useResources = () => useAppSelector((state: RootState) => state.resources.value);

export default resourcesSlice.reducer;
