/* eslint-disable import/prefer-default-export */
import UserProfile from '@models/UserProfile';

export function getUserName(userProfile: UserProfile): string {
  if (userProfile?.userName && userProfile.userId) {
    return `${userProfile.userName} (${userProfile.userId})`;
  }
  if (userProfile?.userName) {
    return userProfile.userId;
  }
  if (!userProfile?.userName && userProfile?.userId) {
    return userProfile.userId;
  }
  return '(Not available)';
}
