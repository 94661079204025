import React from 'react';
import Tooltip from '@mui/material/Tooltip';

interface MessageProps {
  MsgText: string;
  containerClassName: string;
  textClassName: string;
  tooltipColor: string;
}

const Message: React.FC<MessageProps> = ({
  MsgText, containerClassName, textClassName, tooltipColor,
}: MessageProps) => (
  <Tooltip
    title={MsgText}
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: tooltipColor,
          '& .MuiTooltip-arrow': {
            color: tooltipColor,
          },
        },
      },
    }}
  >
    <div className={containerClassName}>
      {MsgText && <div className={textClassName}>{MsgText}</div>}
    </div>
  </Tooltip>
);

export default Message;
