// Them
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import rfdc from 'rfdc';

// Us
// eslint-disable-next-line import/no-cycle
import { RootState, store as theStore } from '@store/store';
import { useAppSelector } from '@store/hooks';

interface ValueMap {
  [key: string]: any[];
}

export interface ValuesState {
  values: ValueMap;
}

const initialState: ValuesState = {
  values: {} as ValueMap,
};

export const localValuesSlice = createSlice({
  name: 'localValues',
  initialState,
  reducers: {
    store: (state, action: PayloadAction<ValueMap>) => {
      state.values = action.payload;
    },
  },
});

export const { store } = localValuesSlice.actions;

export const selectLocalValues = (state: RootState) => state.localValues.values;
export const useLocalValues = () => useAppSelector((state: RootState) => state.localValues);

export function getLocalValue<T>(name: string): T | undefined {
  const values = selectLocalValues(theStore.getState());
  return values[name] as T | undefined;
}

export function useLocalValue<T>(name: string): T | undefined {
  const { values } = useLocalValues();
  return values[name] as T | undefined;
}

export function setLocalValue<T>(name: string, value: T) {
  const values = rfdc()(selectLocalValues(theStore.getState()));
  values[name] = value as any;
  theStore.dispatch(store(values));
}

export default localValuesSlice.reducer;
