// Them
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';

// Us
import Icon from '@components/layout/Icon';
import { IProps, useStepperContext, useValidator } from './StepData';

export default function PickTemplate(props: IProps) {
  const { open } = props;
  const [errorNoTemplate, setErrorNoTemplate] = useState<boolean>(false);
  const {
    selectedTemplate, setSelectedTemplate, templates,
  } = useStepperContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useValidator(props, (enable) => {
    if (!selectedTemplate) {
      setErrorNoTemplate(true);
      return false;
      // eslint-disable-next-line no-else-return
    } else {
      setErrorNoTemplate(false);
    }
    return true;
  }, [templates, selectedTemplate]);

  const addDivider = (key: string, index: number) => (index !== 0 ? (<Divider key={`div:${key}`} component="li" />) : (<></>));

  return (
    <Box sx={{ typography: 'body1', display: open ? '' : 'none' }}>
      <Grid container columns={{ xl: 2 }} sx={{ mt: '0rem' }}>
        <Grid item xs={1} sx={{ padding: '2rem' }}>
          <Typography sx={{ mt: '1rem', mb: '2rem' }}>
            Jumpstart your application by choosing a template from the list on the right.
          </Typography>
          {errorNoTemplate && (
            <Typography sx={{ mt: '5rem', mb: '2rem', color: (theme) => theme.palette.error.main }}>
              Please choose a template to proceed.
            </Typography>
          )}
        </Grid>
        <Grid item xs={1} sx={{ padding: '0rem' }} data-cy="PickTemplate_Templates">
          <Grid container columns={{ xl: 1 }} sx={{ mt: '0rem' }}>
            <Box sx={{ height: '55vh', overflowY: 'auto' }}>
              <List>
                {templates?.map((template, index) => (
                  <div key={template.id}>
                    {addDivider(template.id, index)}
                    <ListItemButton alignItems="flex-start" key={template.id} onClick={() => setSelectedTemplate(template.id)} selected={selectedTemplate === template.id} sx={{ px: 0 }}>
                      <Box
                        sx={{ display: 'grid', gridTemplateColumns: '1fr 12fr' }}
                      >
                        <ButtonBase sx={{
                          objectFit: 'cover',
                          borderRadius: '10px',
                          overflow: 'hidden',
                          width: '65px',
                          height: '65px',
                          cursor: 'pointer',
                          alignSelf: 'center',
                          backgroundColor: (theme) => theme.bpcustom.chromeBackground,
                        }}
                        >
                          <Icon icon={template.icon} color="white" variant="lg" />
                        </ButtonBase>
                        <ListItemText
                          sx={{ ml: '.33rem', '& .MuiListItemText-primary': { fontWeight: 'bold' } }}
                          primary={template.name}
                          secondary={template.description}
                        />
                      </Box>
                    </ListItemButton>
                  </div>
                ))}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
