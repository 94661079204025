// Them
import React, { createContext, useState } from 'react';

// Us

export type GeneralUXContextType = {
  openChatbot: boolean;
  expandChatbot: boolean;
  setOpenChatbot: (open: boolean) => void;
  setExpandChatbot: (open: boolean) => void;
};

export const GeneralUXContext = createContext<GeneralUXContextType>({
  openChatbot: false,
  expandChatbot: false,
  setOpenChatbot: (open: boolean) => {},
  setExpandChatbot: (open: boolean) => {},
});

interface IProps {
  children?: React.ReactNode
}

export default function GeneralUXContextProvider({ children }: IProps) {
  const [openChatbot, setOpenChatbot] = useState(false);
  const [expandChatbot, setExpandChatbot] = useState(false);

  return (
    <GeneralUXContext.Provider value={{
      openChatbot,
      expandChatbot,
      setOpenChatbot,
      setExpandChatbot,
    }}
    >
      {children ?? <></>}
    </GeneralUXContext.Provider>
  );
}
