// Them
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Us
import {
  IProps, useStepperContext, useValidator,
} from './StepData';
import FolderTree from '../FolderTree';

export default function PickFolder(props: IProps) {
  const { open } = props;
  const [errorSelectedFolder, setErrorSelectedFolder] = useState<string>('');
  const [errorObjectName, setErrorObjectName] = useState<string>('');
  const {
    fileOptions: { selectedFile },
    selectedFolder,
    setSelectedFolder,
    objectName,
    setObjectName,
  } = useStepperContext();

  // Revise this after forwarding object type from first page.
  useEffect(() => {
    if (selectedFile && !objectName) {
      setObjectName(selectedFile.name);
    }
  }, [objectName, selectedFile, setObjectName]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useValidator(props, (enable) => {
    let error = false;
    if (!selectedFolder[0]) {
      setErrorSelectedFolder('Please select a folder');
      error = true;
    } else {
      setErrorSelectedFolder('');
    }
    if (!objectName) {
      setErrorObjectName('Please provide an object name');
      error = true;
    } else {
      setErrorObjectName('');
    }
    return !error;
  }, [objectName, selectedFolder]);

  return (
    <Box sx={{ typography: 'body1', display: open ? '' : 'none' }}>
      <Grid container columns={{ md: 2 }} sx={{ marginTop: '0rem' }}>
        <Grid xs={1} sx={{ padding: '2rem' }}>
          <Box>
            <Typography sx={{ marginBottom: '2rem', marginTop: '2rem' }}>
              Now give your object a name and identify a folder to create it in.
            </Typography>
            <TextField
              aria-label="object name"
              label="Object Name"
              value={objectName}
              helperText={errorObjectName}
              error={!!errorObjectName}
              onChange={(e) => setObjectName(e.currentTarget.value)}
              variant="standard"
              sx={{ width: '80%', mb: '2rem' }}
            />
            <TextField
              aria-label="selected folder"
              label="Selected Folder"
              helperText={errorSelectedFolder || 'Select a folder in the tree'}
              error={!!errorSelectedFolder}
              InputProps={{
                readOnly: true,
              }}
              value={selectedFolder[1]}
              variant="standard"
              sx={{ width: '80%', mb: '1rem' }}
            />
          </Box>
        </Grid>
        <Grid
          xs={1}
          sx={{
            height: '55vh', overflow: 'auto',
          }}
        >
          <FolderTree onSelectFolder={setSelectedFolder} selected={selectedFolder[0]} />
        </Grid>
      </Grid>
    </Box>
  );
}
