// Them
import React, {
  useCallback, useState, useEffect, useRef,
} from 'react';
// import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

// Us
import { useClientHubLiveStats } from '@components/data/clientHubLiveStats';

export default function LiveStats() {
  const [logs, setLogs] = useState(new Array<string>());
  const [pausedLogs, setPausedLogs] = useState(new Array<string>());
  const [autoScroll, setAutoScroll] = useState(true);
  const [paused, setPaused] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const reportLiveStatsRef = useRef<(msg: string) => void>();

  const togglePaused = useCallback(() => {
    setPaused(!paused);
    if (paused) {
      setLogs((oldLogs) => [...oldLogs, ...pausedLogs]);
      setPausedLogs(new Array<string>());
    }
  }, [paused, pausedLogs]);

  reportLiveStatsRef.current = useCallback((msg) => {
    if (paused) {
      setPausedLogs((oldLogs) => [...oldLogs, msg]);
    } else {
      setLogs((oldLogs) => [...oldLogs, msg]);
    }
  }, [paused]);

  useClientHubLiveStats(reportLiveStatsRef);

  useEffect(() => {
    if (textareaRef.current && autoScroll) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [logs, autoScroll]);

  return (
    <>
      <Box display="flex" alignItems="center" gap={2} sx={{ marginBottom: '1rem' }}>
        <FormControlLabel
          control={<Switch defaultChecked onChange={() => setAutoScroll(!autoScroll)} />}
          label={`Auto-scroll ${(autoScroll ? 'enabled' : 'disabled')}`}
        />
        <Button variant="contained" onClick={() => togglePaused()}>
          {`${(paused ? 'Resume' : 'Pause')}`}
        </Button>
        <Button variant="contained" component="label" onClick={() => setLogs(new Array<string>())}>
          Clear
        </Button>
      </Box>
      <textarea ref={textareaRef} style={{ width: '99%', height: '93vh' }} value={logs.join('')} readOnly />
    </>
  );
}
